import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faGear } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button } from '@skiwo/components';
import { format } from 'date-fns';
import { useGetJobSystemLogs } from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import SystemLogLoader from '../../../components/SystemLog/SystemLogLoader';
import usePagination from '../../../hooks/usePagination';
import translationKeys from '../../../translations/translationKeys';
import JobSystemLog from '../../../types/JobSystemLog';
import { SystemLogActorRole } from '../../../types/ManagerOrderSystemLog';
import useGetJobIdFromParam from '../../hooks/useGetJobIdFromParam';
import getTextForLog from './helpers/getLogText';
import { getValuesByType } from './helpers/getValuesByType';
import styles from './SystemLogDrawer.module.scss';

const SystemLogTabSection = () => {
  const intl = useIntl();
  const { pagination, setPagination } = usePagination();
  const [logs, setLogs] = useState<JobSystemLog[]>([]);

  const jobId = useGetJobIdFromParam();

  const { data, isLoading } = useGetJobSystemLogs({
    id: jobId,
    urlParams: { page: pagination.page },
  });

  const getActorAvatar = (logItem: JobSystemLog) => {
    if (logItem.actorRole === SystemLogActorRole.System) {
      return (
        <div className={styles.systemIcon}>
          <FontAwesomeIcon icon={faGear} />
        </div>
      );
    }

    const avatarName = logItem.actor?.name || logItem.adminActor?.name || 'unknown';
    const avatarUrl = logItem.actor?.avatar;

    return <Avatar altText={avatarName} url={avatarUrl} size="medium" />;
  };

  useEffect(() => {
    if (!data) return;

    const appendData = data.pagination.page > 1;

    setLogs(appendData ? (prev) => [...prev, ...data.collection] : data.collection);
    setPagination({ page: data.pagination.page, totalPages: data.pagination.pages });
  }, [data]);

  return (
    <>
      <div className={styles.systemLog}>
        {isLoading && !logs && <SystemLogLoader />}

        {logs?.map((logItem, index) => {
          const createdAt = new Date(logItem.createdAt);
          return (
            <div key={index} className={styles.logItem}>
              <div className={styles.avatar}>{getActorAvatar(logItem)}</div>

              <div>
                <div className={styles.logSubject}>
                  <span>{getTextForLog(logItem, intl)}</span>
                </div>

                <span className={styles.date}>{format(createdAt, 'dd.MM.yyyy HH:mm')}</span>

                {getValuesByType(logItem, intl)}
              </div>
            </div>
          );
        })}
      </div>

      {pagination.page < pagination.totalPages && (
        <div className={styles.loadMoreButton}>
          <Button
            size="large"
            variant="secondary"
            isLoading={isLoading}
            onClick={() => {
              setPagination({ ...pagination, page: pagination.page + 1 });
            }}
          >
            <FormattedMessage id={translationKeys.translation_order_system_logs_load_more_button} />
          </Button>
        </div>
      )}
    </>
  );
};

export default SystemLogTabSection;
