export enum ManagerOrderSystemLogType {
  // Order static types
  OrderTransferredToXtrf = 'order_transferred_to_xtrf',
  OrderOcrTriggered = 'order_ocr_triggered',
  OrderCreated = 'order_created',
  // OrderNotification = 'order_notification',
  OrderAttachmentDownloaded = 'order_attachment_downloaded',
  // Order dynamic types
  OrderUpdated = 'order_updated',
  OrderUpdatedName = 'order_updated_name',
  OrderUpdatedStatus = 'order_updated_status',
  OrderUpdatedWordCount = 'order_updated_count_of_words',
  OrderUpdatedAccessLevel = 'order_updated_access_level',
  OrderUpdatedWaitingForCustomer = 'order_updated_customer_input_status',
  OrderUpdatedCaseNumber = 'order_updated_case_number',
  OrderUpdatedPaymentMethod = 'order_updated_payment_method_id',
  OrderUpdatedBookerReference = 'order_updated_booking_reference',
  OrderUpdatedClientName = 'order_updated_client_name',
  OrderUpdatedSendByPost = 'order_updated_sent_by_post',
  OrderUpdatedSendByPostRecipientName = 'order_updated_send_by_post_name',
  OrderUpdatedSendByPostAddress = 'order_updated_send_by_post_address',
  OrderUpdatedSendByPostEnterprise = 'order_updated_send_by_post_enterprise_name',
  OrderUpdatedSendByPostCoverLetter = 'order_updated_send_by_post_cover_letter',
  OrderUpdatedBookerEnterprise = 'order_updated_enterprise_id',
  OrderUpdatedBookerDepartment = 'order_updated_department_id',
  OrderUpdatedExternalDeadline = 'order_updated_external_deadline',
  OrderUpdatedAlternativeLanguage = 'order_updated_alternative_target_language_id',
  OrderUpdatedSourceLanguage = 'order_updated_source_language_id',
  OrderUpdatedSource = 'order_updated_source_type',
  OrderUpdatedSubject = 'order_updated_translation_method_id',
  OrderUpdatedDescription = 'order_updated_instructions_from_demander',
  OrderUpdatedOrderReference = 'order_updated_payment_booking_reference',
  OrderUpdatedClientNumber = 'order_updated_client_id',
  OrderUpdatedInvoicing = 'order_updated_invoicing',
  OrderUpdatedOtherSubject = 'order_updated_other_subject',
  OrderUpdatedInternalNote = 'order_updated_internal_note',
  OrderUpdatedIsLoggedOut = 'order_updated_is_logged_out',
  OrderUpdatedDeliveryMethod = 'order_updated_delivery_method',
  OrderUpdatedDemanderFeedback = 'order_updated_demander_feedback',
  OrderUpdatedLoggedOutPhoneCode = 'order_updated_logged_out_booker_phone_code',
  OrderUpdatedLoggedOutPhoneNumber = 'order_updated_logged_out_booker_phone_number',
  OrderUpdatedLoggedOutLastName = 'order_updated_logged_out_booker_last_name',
  OrderUpdatedLoggedOutBookerOrgNumber = 'order_updated_logged_out_booker_org_number',
  OrderUpdatedLoggedOutPaymentOrgNumber = 'order_updated_logged_out_booker_payment_org_number',
  OrderUpdatedLoggedOutBookerOrgName = 'order_updated_logged_out_booker_org_name',
  OrderUpdatedLoggedOutBookerFirstName = 'order_updated_logged_out_booker_first_name',
  OrderUpdatedLoggedOutBookerEmail = 'order_updated_logged_out_booker_email',
  OrderUpdatedRequestedShortNotice = 'order_updated_requested_short_notice',
  OrderUpdatedCCEmails = 'order_updated_cc_emails',
  OrderUpdatedCoverLetters = 'order_updated_cover_letters',

  // Task static types
  TaskCreated = 'task_created',
  TaskReplaced = 'task_replaced',
  // Task dynamic types
  TaskUpdated = 'task_updated',
  TaskUpdatedStatus = 'task_updated_status',
  TaskUpdatedTargetLanguage = 'task_updated_target_language_id',

  // Subtask static types
  SubtaskCreated = 'subtask_created',
  SubtaskApplied = 'subtask_applied',
  SubtaskRejected = 'subtask_rejected',
  SubtaskAccepted = 'subtask_accepted',
  SubtaskReviewRequested = 'subtask_review_requested',
  SubtaskInvited = 'subtask_invited',
  SubtaskDeadlineChangeRequestCreated = 'subtask_deadline_change_request_created',
  // SubtaskDeadlineChangeRequestApproved = 'subtask_deadline_change_request_approved',
  // SubtaskDeadlineChangeRequestRejected = 'subtask_deadline_change_request_rejected',
  SubtaskStatementUpdated = 'subtask_statement_updated',
  // Subtask dynamic types
  SubtaskUpdated = 'subtask_updated',
  SubtaskUpdatedStatus = 'subtask_updated_status',
  SubtaskUpdatedInternalDeadline = 'subtask_updated_internal_deadline',
  SubtaskUpdatedSellerWordCount = 'subtask_updated_count_of_words',
  SubtaskUpdatedSellerQuote = 'subtask_updated_seller_quote_amount',
  SubtaskUpdatedSalitaInstructions = 'subtask_updated_instructions_from_project_manager_to_seller',
  SubtaskUpdatedType = 'subtask_updated_subtask_type',
  SubtaskUpdatedDeliveryDate = 'subtask_updated_delivered_on',
  SubtaskUpdatedSalitaQuote = 'subtask_updated_platform_quote_amount',
  SubtaskUpdatedDeliveredShortNotice = 'subtask_updated_delivered_short_notice',
  SubtaskUpdatedReviewComments = 'subtask_updated_review_comment_from_project_manager',
  SubtaskUpdatedTranslatorComments = 'subtask_updated_review_comment_from_seller',
  SubtaskUpdatedAssignedSeller = 'subtask_updated_seller_id',
  SubtaskUpdatedAssignedQA = 'subtask_updated_quality_assurer_uid',
  SubtaskUpdatedTargetLanguage = 'subtask_updated_target_language_id',
  SubtaskUpdatedSourceLanguage = 'subtask_updated_source_language_id',

  // FileUploaded = 'file_uploaded',
  FileArchived = 'file_archived',
  FileUnarchived = 'file_unarchived',

  AnnounceOrderCancelledTo = 'announce_order_cancelled_to',
  AnnounceCcFinishedOrderTo = 'announce_cc_finished_order_to',
  AnnounceOrderCoverLetterRejectedTo = 'announce_order_cover_letter_rejected_to',
  AnnounceCustomerFeedbackForServiceTo = 'announce_customer_feedback_for_service_to',
  AnnounceCustomerPostedOrderTo = 'announce_customer_posted_order_to',
  AnnounceCustomerUpdatedOrderTo = 'announce_customer_updated_order_to',
  AnnounceEarlyDeadlineWarningTo = 'announce_early_deadline_warning_to',
  AnnounceFinishedOrderTo = 'announce_finished_order_to',
  AnnounceInProgressOrderTo = 'announce_in_progress_order_to',
  AnnounceOrderLogoutPostedTo = 'announce_order_logout_posted_to',
  AnnounceOrderMovedToXtrfTo = 'announce_order_moved_to_xtrf_to',
  AnnouncePostedOrderTo = 'announce_posted_order_to',
  AnnounceOrderQuoteTo = 'announce_order_quote_to',
  AnnounceSavedResponseTo = 'announce_saved_response_to',
  AnnounceOrderSentByPostTo = 'announce_order_sent_by_post_to',
  AnnounceOrderTaskFinishedTo = 'announce_order_task_finished_to',
  AnnounceSubtaskAcceptedTo = 'announce_subtask_accepted_to',
  AnnounceSubtaskCancelledTo = 'announce_subtask_cancelled_to',
  AnnounceSubtaskChangesRequiredTo = 'announce_subtask_changes_required_to',
  AnnounceSubtaskInternalDeadlineChangeRequestedTo = 'announce_subtask_internal_deadline_change_requested_to',
  AnnounceSubtaskInternalDeadlinePassedTo = 'announce_subtask_internal_deadline_passed_to',
  AnnounceSubtaskInviteReminderTo = 'announce_subtask_invite_reminder_to',
  AnnounceSubtaskInvitedTo = 'announce_subtask_invited_to',
  AnnounceSubtaskPublishedTo = 'announce_subtask_published_to',
  AnnounceSubtaskRejectedTo = 'announce_subtask_rejected_to',
}

export enum SystemLogActionType {
  action = 'action',
  notification = 'notification',
}

export enum SystemLogActorRole {
  Demander = 'demander',
  Supplier = 'supplier',
  Admin = 'admin',
  System = 'system',
  Unknown = 'unknown',
  Manager = 'manager',
}

interface ManagerOrderSystemLog {
  id: number;
  logType: ManagerOrderSystemLogType;
  createdAt: string;
  actorRole: SystemLogActorRole;
  loggedActionType: SystemLogActionType;
  context?: string;
  oldValue?: string;
  value?: string;
  recipient?: {
    name?: string;
    avatar?: string;
  };
  actor?: {
    name?: string;
    avatar?: string;
  };
  adminActor?: {
    name?: string;
    avatar?: string;
  };
}

export interface ManagerOrderSystemLogResponse {
  collection: ManagerOrderSystemLog[];
  pagination: {
    count: number;
    page: number;
    pages: number;
  };
}

export default ManagerOrderSystemLog;
