import { IntlShape } from 'react-intl';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerJobInviteStatus } from '../../../../types';

export const getAutoInviteStatusLabel = (status: ManagerJobInviteStatus, intl: IntlShape) => {
  switch (status) {
    case ManagerJobInviteStatus.Disabled:
      return intl.formatMessage({
        id: translationKeys.job_system_log_auto_invite_disabled,
      });
    case ManagerJobInviteStatus.Finished:
      return intl.formatMessage({
        id: translationKeys.job_system_log_auto_invite_finished,
      });
    case ManagerJobInviteStatus.NoSuitableInterpreters:
      return intl.formatMessage({
        id: translationKeys.job_system_log_auto_invite_no_suitable_interpreters,
      });
    case ManagerJobInviteStatus.Ongoing:
      return intl.formatMessage({
        id: translationKeys.job_system_log_auto_invite_ongoing,
      });
    case ManagerJobInviteStatus.Paused:
      return intl.formatMessage({
        id: translationKeys.job_system_log_auto_invite_paused,
      });
    default:
      return '-';
  }
};
