import React from 'react';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SystemLogTag, { SystemLogTagColor } from './SystemLogTag/SystemLogTag';
import styles from './SystemLog.module.scss';

interface SystemLogDescriptionValuesProps {
  oldValue?: string;
  value: string;
  oldValueTagColor?: SystemLogTagColor;
  valueTagColor?: SystemLogTagColor;
}

const SystemLogDescriptionValues = ({
  oldValue,
  value,
  oldValueTagColor,
  valueTagColor,
}: SystemLogDescriptionValuesProps) => {
  return (
    <div className={styles.descriptionValues}>
      <div className={styles.descriptionOldValueWrapper}>
        <SystemLogTag color={oldValueTagColor || 'neutral'} className={styles.descriptionOldValue}>
          {oldValue || '-'}
        </SystemLogTag>
        <span className={styles.descriptionArrow}>
          <FontAwesomeIcon icon={faArrowRight} />
        </span>
      </div>
      <SystemLogTag color={valueTagColor || 'neutral'} className={styles.descriptionValue}>
        {value}
      </SystemLogTag>
    </div>
  );
};

export default SystemLogDescriptionValues;
