import { IntlShape } from 'react-intl';
import getJobStatusName from '../../Jobs/utils/getJobStatusName';
import { ManagerJobStatus } from '../../types';

export const statuses = [
  ManagerJobStatus.Published,
  ManagerJobStatus.Applied,
  ManagerJobStatus.Accepted,
  ManagerJobStatus.InProgress,
  ManagerJobStatus.Finished,
];

export const getStatuses = (
  status: ManagerJobStatus,
  previousStatus: ManagerJobStatus,
  intl: IntlShape,
) => {
  const lastStatusIndex = statuses.indexOf(previousStatus);
  if (lastStatusIndex !== -1) {
    return [...statuses.slice(0, lastStatusIndex + 1), status].map((s) =>
      getJobStatusName(s, intl),
    );
  }
  return [getJobStatusName(status, intl)];
};

export const getStepperVariant = (status: ManagerJobStatus): 'primary' | 'error' | 'closed' => {
  switch (status) {
    case ManagerJobStatus.Cancelled:
      return 'error';
    case ManagerJobStatus.Closed:
      return 'closed';
    default:
      return 'primary';
  }
};
