import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import {  ManagerJobWithdrawReason } from '../../types/ManagerJob';

const getWithdrawReason = (reason: ManagerJobWithdrawReason, intl: IntlShape) => {
  const translation = translationKeys[`job_withdraw_modal_reason_${reason}`];

  if (translation) {
    return intl.formatMessage({ id: translation });
  } else {
    return reason;
  }
};

export default getWithdrawReason;
