import { useEffect } from 'react';

export function useScrollToFormikError(isSubmitting: boolean) {
  useEffect(() => {
    if (!isSubmitting) return;

    const el = document.querySelector('[class*="errorMessage"]');
    if (el) {
      window.scrollTo({
        behavior: 'smooth',
        top:
          (el.parentElement ?? el).getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          80,
      });
    }
  }, [isSubmitting]);
}
