import React from 'react';
import { IntlShape } from 'react-intl';
import { format } from 'date-fns';
import { BooleanValueVariant } from '../../../../components/BooleanValue/BooleanValue';
import { SystemLogValues } from '../../../../components/SystemLog/SystemLogValues';
import {
  getBookingMechanismLabel,
  getGenderLabel,
} from '../../../../CreateInterpretationOrder/utils';
import { getBooleanLabel } from '../../../../helpers/getBooleanLabel';
import { getBooleanTranslation } from '../../../../TranslationOrder/OrderDetails/Drawers/SystemLogDrawer/helpers/getBooleanTranslations';
import {
  ManagerJobAlternativeLanguageProcessing,
  ManagerJobBookingMechanism,
  ManagerJobGender,
  ManagerJobGenderProcessing,
  ManagerJobInviteStatus,
  ManagerJobQualificationProcessing,
  ManagerJobSessionType,
  ManagerJobSessionTypeProcessing,
  ManagerJobStandbyProcessing,
} from '../../../../types';
import JobSystemLog, { JobSystemLogType } from '../../../../types/JobSystemLog';
import { getSessionTypeLabel } from '../../../utils/getSessionTypeLabel';
import { getAutoInviteStatusLabel } from './getAutoInviteStatusLabel';
import { getProcessingLabel } from './getProcessingLabel';

export const getValuesByType = (logItem: JobSystemLog, intl: IntlShape) => {
  if (!logItem.value) return null;
  
  switch (logItem.logType) {
    case JobSystemLogType.UpdatedGender:
    case JobSystemLogType.UpdatedGenderForcefully:
    case JobSystemLogType.JobUpdatedSpecificGender:
      return (
        <SystemLogValues
          oldValue={getGenderLabel(logItem.oldValue as ManagerJobGender, intl) || '-'}
          value={getGenderLabel(logItem.value as ManagerJobGender, intl)}
        />
      );
    case JobSystemLogType.JobUpdatedProcessRequirementGenderProcessing:
      return (
        <SystemLogValues
          oldValue={getProcessingLabel(logItem.oldValue as ManagerJobGenderProcessing, intl) || '-'}
          value={getProcessingLabel(logItem.value as ManagerJobGenderProcessing, intl)}
        />
      );
    case JobSystemLogType.JobUpdatedProcessRequirementSessionTypeProcessing:
      return (
        <SystemLogValues
          oldValue={
            getProcessingLabel(logItem.oldValue as ManagerJobSessionTypeProcessing, intl) || '-'
          }
          value={getProcessingLabel(logItem.value as ManagerJobSessionTypeProcessing, intl)}
        />
      );
    case JobSystemLogType.JobUpdatedProcessRequirementAlternativeLanguageProcessing:
      return (
        <SystemLogValues
          oldValue={
            getProcessingLabel(logItem.oldValue as ManagerJobAlternativeLanguageProcessing, intl) ||
            '-'
          }
          value={getProcessingLabel(logItem.value as ManagerJobAlternativeLanguageProcessing, intl)}
        />
      );
    case JobSystemLogType.JobUpdatedProcessRequirementQualificationProcessing:
      return (
        <SystemLogValues
          oldValue={
            getProcessingLabel(logItem.oldValue as ManagerJobQualificationProcessing, intl) || '-'
          }
          value={getProcessingLabel(logItem.value as ManagerJobQualificationProcessing, intl)}
        />
      );
    case JobSystemLogType.UpdatedSessionType:
    case JobSystemLogType.UpdatedSessionTypeForcefully:
    case JobSystemLogType.JobUpdatedInterpretationRequirementSessionType:
    case JobSystemLogType.JobUpdatedProcessRequirementAutoUpdateToSessionType:
      return (
        <SystemLogValues
          oldValue={getSessionTypeLabel(logItem.oldValue as ManagerJobSessionType, intl) || '-'}
          value={getSessionTypeLabel(logItem.value as ManagerJobSessionType, intl)}
        />
      );
    case JobSystemLogType.JobUpdatedStartTime:
    case JobSystemLogType.JobUpdatedFinishTime:
    case JobSystemLogType.JobUpdatedManualBookingConfirmationDeadline:
    case JobSystemLogType.JobUpdatedManualBookingDate:
    case JobSystemLogType.JobUpdatedProcessRequirementNextInvitationTime:
      return (
        <SystemLogValues
          oldValue={
            logItem.oldValue && logItem.oldValue !== '-'
              ? format(new Date(logItem.oldValue), 'dd.MM.yyyy HH:mm')
              : '-'
          }
          value={logItem.value !== '-' ? format(new Date(logItem.value), 'dd.MM.yyyy HH:mm') : '-'}
        />
      );
    case JobSystemLogType.JobUpdatedAutoAward:
    case JobSystemLogType.JobUpdatedAutoAwardAllowed:
    case JobSystemLogType.JobUpdatedProactive:
    case JobSystemLogType.JobUpdatedManualBookingFeeApplied:
    case JobSystemLogType.JobUpdatedManualEditingFeeApplied:
    case JobSystemLogType.JobUpdatedManualTravelBookingFeeApplied:
      return (
        <SystemLogValues
          oldValue={getBooleanTranslation({ value: logItem.oldValue, intl })}
          value={getBooleanTranslation({ value: logItem.value, intl })}
        />
      );
    case JobSystemLogType.JobUpdatedInterpretationRequirementSpecificQualificationRequested: {
      const oldValue = logItem.oldValue === 'true' ? true : false;
      const value = logItem.value === 'true' ? true : false;
      return (
        <SystemLogValues
          oldValue={getBooleanLabel(oldValue, intl, BooleanValueVariant.YesNo)}
          value={getBooleanLabel(value, intl, BooleanValueVariant.YesNo)}
        />
      );
    }
    case JobSystemLogType.JobUpdatedStandby: {
      const oldValue = logItem.oldValue === ManagerJobStandbyProcessing.TurnedOn ? true : false;
      const value = logItem.value === ManagerJobStandbyProcessing.TurnedOn ? true : false;
      return (
        <SystemLogValues
          oldValue={getBooleanLabel(oldValue, intl)}
          value={getBooleanLabel(value, intl)}
        />
      );
    }
    case JobSystemLogType.JobUpdatedAutoInvite: {
      const oldValue = logItem.oldValue === 'on' ? true : false;
      const value = logItem.value === 'on' ? true : false;
      return (
        <SystemLogValues
          oldValue={getBooleanLabel(oldValue, intl)}
          value={getBooleanLabel(value, intl)}
        />
      );
    }
    case JobSystemLogType.JobUpdatedManualBookingMethod:
      return (
        <SystemLogValues
          oldValue={
            getBookingMechanismLabel(logItem.oldValue as ManagerJobBookingMechanism, intl) || '-'
          }
          value={getBookingMechanismLabel(logItem.value as ManagerJobBookingMechanism, intl) || '-'}
        />
      );
    case JobSystemLogType.JobUpdatedProcessRequirementAutoInviteStatus:
      return (
        <SystemLogValues
          oldValue={getAutoInviteStatusLabel(logItem.oldValue as ManagerJobInviteStatus, intl)}
          value={getAutoInviteStatusLabel(logItem.value as ManagerJobInviteStatus, intl)}
        />
      );
    default:
      return logItem.value && <SystemLogValues oldValue={logItem.oldValue} value={logItem.value} />;
  }
};
