import React from 'react';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SystemLogTag, { SystemLogTagColor } from './SystemLogTag/SystemLogTag';
import styles from './SystemLog.module.scss';

interface SystemLogValuesProps {
  oldValue?: string;
  value: string;
  oldValueTagColor?: SystemLogTagColor;
  valueTagColor?: SystemLogTagColor;
}

export const SystemLogValues = ({
  oldValue,
  value,
  oldValueTagColor,
  valueTagColor,
}: SystemLogValuesProps) => {
  return (
    <div className={styles.values}>
      {typeof oldValue === 'string' && (
        <>
          <SystemLogTag color={oldValueTagColor || 'neutral'}>{oldValue || '-'}</SystemLogTag>
          <span className={styles.arrow}>
            <FontAwesomeIcon icon={faArrowRight} />
          </span>
        </>
      )}
      <SystemLogTag color={valueTagColor || 'neutral'}>{value || '-'}</SystemLogTag>
    </div>
  );
};
