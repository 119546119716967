import { IntlShape } from 'react-intl';
import translationKeys from '../../../../translations/translationKeys';
import {
  ManagerJobAlternativeLanguageProcessing,
  ManagerJobGenderProcessing,
  ManagerJobQualificationProcessing,
  ManagerJobSessionTypeProcessing,
} from '../../../../types';

export const getProcessingLabel = (
  processing:
    | ManagerJobGenderProcessing
    | ManagerJobSessionTypeProcessing
    | ManagerJobQualificationProcessing
    | ManagerJobAlternativeLanguageProcessing,
  intl: IntlShape,
) => {
  switch (processing) {
    case ManagerJobGenderProcessing.GenderAutoUpdate:
    case ManagerJobSessionTypeProcessing.SessionTypeAutoUpdate:
    case ManagerJobQualificationProcessing.qualification_auto_update:
    case ManagerJobAlternativeLanguageProcessing.AlternativeLanguageAutoUpdate:
      return intl.formatMessage({
        id: translationKeys.job_system_log_find_any_interpreter,
      });
    case ManagerJobGenderProcessing.GenderCancelNotFound:
    case ManagerJobSessionTypeProcessing.SessionTypeCancelNotFound:
    case ManagerJobQualificationProcessing.qualification_cancel_not_found:
      return intl.formatMessage({
        id: translationKeys.job_system_log_cancel_not_found,
      });
    case ManagerJobGenderProcessing.GenderNoAction:
    case ManagerJobSessionTypeProcessing.SessionTypeNoAction:
    case ManagerJobQualificationProcessing.qualification_no_action:
    case ManagerJobAlternativeLanguageProcessing.AlternativeLanguageNoAction:
      return intl.formatMessage({
        id: translationKeys.job_system_log_no_action,
      });
    default:
      return '-';
  }
};
