import React, { useEffect, useState } from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  RadioOption,
  RadioOptions,
  SearchDropdown,
  SearchDropdownMenuOption,
} from '@skiwo/components';
import { PaymentSectionSkeleton } from '../../CreateInterpretationOrder/PaymentSection/PaymentSectionSkeleton';
import { getPaymentMethodName } from '../../helpers/getPaymentMethodName';
import { useApi } from '../../providers/ApiProvider';
import { useToast } from '../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../translations/translationKeys';
import { CustomerPaymentMethod } from '../../types';
import CreatePaymentDrawer from '../CreatePaymentDrawer/CreatePaymentDrawer';
import styles from './PaymentMethodSelector.module.scss';

interface PaymentMethodSelectorProps {
  customerUid: string;
  radioItemsLimit?: number;
  initialPaymentMethodId?: string;
  selectedPaymentMethod?: CustomerPaymentMethod;
  setSelectedPaymentMethod: (paymentMethod: CustomerPaymentMethod) => void;
  customerName: string;
  customerEnterpriseName: string;
  customerEnterpriseId: string;
}

export const PaymentMethodSelector = ({
  customerUid,
  radioItemsLimit = 2,
  initialPaymentMethodId,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  customerName,
  customerEnterpriseName,
  customerEnterpriseId,
}: PaymentMethodSelectorProps) => {
  const intl = useIntl();
  const api = useApi();
  const { showErrorToast } = useToast();
  const [paymentMethods, setPaymentMethods] = useState<CustomerPaymentMethod[]>([]);
  const [showCreateDrawer, setShowCreateDrawer] = useState(false);
  const [paymentMethodsLoading, setPaymentMethodsLoading] = useState(false);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<RadioOption[]>([]);
  const [paymentMethodDropdownOptions, setPaymentMethodDropdownOptions] = useState<
    SearchDropdownMenuOption[]
  >([]);

  const getPaymentMethods = async () => {
    const { data, error } = await api.getPaymentMethods(
      {
        customerUid,
      },
      setPaymentMethodsLoading,
    );

    if (data) {
      const activePaymentMethods = data.filter((item) => !item.archived);
      setPaymentMethods(activePaymentMethods);

      const paymentOptions: RadioOption[] = activePaymentMethods
        .map((option) => {
          const title = option.orgNumber ? option.orgName : option.email;
          return {
            id: option.id.toString(),
            title: (
              <div className={styles.paymentMethodTitle}>
                <span>{title}</span>
                <span className={styles.paymentMethodOrgNumber}>
                  {option.orgNumber ||
                    intl.formatMessage({
                      id: translationKeys.translation_order_edit_invoicing_payment_methods_email_label,
                    })}
                </span>
              </div>
            ),
            default: option.default,
          };
        })
        .sort((a, b) => {
          if (b.default) {
            return 1;
          } else {
            return a.default ? -1 : 0;
          }
        });

      const paymentDropdownOptions: SearchDropdownMenuOption[] = activePaymentMethods.map(
        (option) => {
          return {
            id: option.id,
            label: getPaymentMethodName(
              {
                orgName: option.orgName,
                orgNumber: option.orgNumber,
                email: option.email,
              },
              intl,
            ),
            key: option.id.toString(),
          };
        },
      );

      setPaymentMethodOptions(paymentOptions);
      setPaymentMethodDropdownOptions(paymentDropdownOptions);

      if (!initialPaymentMethodId && paymentOptions[0]) {
        const selectedMethod = activePaymentMethods.find(
          (method) => method.id.toString() === paymentOptions[0].id,
        );

        if (selectedMethod) setSelectedPaymentMethod(selectedMethod);
      }

      if (initialPaymentMethodId) {
        const selectedMethod = activePaymentMethods.find(
          (method) => method.id.toString() === initialPaymentMethodId,
        );

        if (selectedMethod) setSelectedPaymentMethod(selectedMethod);
      }
    }

    if (error) {
      showErrorToast(error);
    }
  };

  const handleSelectPaymentMethod = (selectedId: string) => {
    const selectedMethod = paymentMethods.find((method) => method.id.toString() === selectedId);

    if (selectedMethod) {
      setSelectedPaymentMethod(selectedMethod);
    }
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);

  return paymentMethodsLoading ? (
    <PaymentSectionSkeleton />
  ) : (
    <div>
      {paymentMethodOptions.length <= radioItemsLimit && (
        <div className={styles.paymentMethods} data-testid="radio-options">
          <RadioOptions
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_payment_method_label,
            })}
            options={paymentMethodOptions}
            onSelect={(selectedId) => handleSelectPaymentMethod(selectedId)}
            block
            selected={selectedPaymentMethod ? selectedPaymentMethod.id.toString() : undefined}
          />
          <button
            className={styles.newOption}
            onClick={() => setShowCreateDrawer(true)}
            data-testid="add-payment-button"
            type="button"
          >
            <FontAwesomeIcon icon={faPlus} />
            <FormattedMessage
              id={translationKeys.create_translation_order_invoicing_add_new_button}
            />
          </button>
        </div>
      )}
      {paymentMethodOptions.length > radioItemsLimit && (
        <div className={styles.dropdownPaymentMethods} data-testid="dropdown-options">
          <B_Form.Label>
            <FormattedMessage
              id={translationKeys.translation_order_edit_invoicing_payment_methods_label}
            />
          </B_Form.Label>
          <div className={styles.selectorWithButton}>
            <div className={styles.selector}>
              <SearchDropdown
                options={paymentMethodDropdownOptions}
                selectedKeys={selectedPaymentMethod ? [selectedPaymentMethod.id.toString()] : []}
                onChange={(option) => {
                  if (option && option.length > 0 && option[0].key) {
                    handleSelectPaymentMethod(option[0].id.toString());
                  }
                }}
                size="large"
              />
            </div>
            <Button
              variant="gray"
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => setShowCreateDrawer(true)}
              size="x-large"
              className={styles.button}
              data-testid="add-payment-button"
              type="button"
            >
              <FormattedMessage
                id={translationKeys.create_translation_order_invoicing_add_new_button}
              />
            </Button>
          </div>
        </div>
      )}

      <CreatePaymentDrawer
        show={showCreateDrawer}
        onClose={() => {
          setShowCreateDrawer(false);
          getPaymentMethods();
        }}
        customerName={customerName}
        enterpriseName={customerEnterpriseName}
        enterpriseId={customerEnterpriseId}
      />
    </div>
  );
};
