import React, { useState } from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faHeadset } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Banner, DatePicker, TextField, TimePicker } from '@skiwo/components';
import classNames from 'classnames';
import { format } from 'date-fns';
import { FormikProps } from 'formik';
import CreateOrderSectionContainer from '../../components/CreateOrderSectionContainer/CreateOrderSectionContainer';
import translationKeys from '../../translations/translationKeys';
import { ManagerJobBookingMechanism } from '../../types';
import { CreateInterpretationOrderFormValues } from '../schema';
import styles from './ManualBookingSection.module.scss';

interface ManualBookingSectionProps {
  formikProps: FormikProps<CreateInterpretationOrderFormValues>;
}

export const ManualBookingSection = ({ formikProps }: ManualBookingSectionProps) => {
  const intl = useIntl();
  const [showBookingDatePicker, setShowBookingDatePicker] = useState(false);
  const [showDeadlineDatePicker, setShowDeadlineDatePicker] = useState(false);

  const handleBookingInformationDate = (date: Date) => {
    if (date) {
      formikProps.setFieldValue('actualCreatedAtDate', date);
    }
    setShowBookingDatePicker(false);
  };

  const handleDeadlineDate = (date: Date) => {
    if (date) {
      formikProps.setFieldValue('confirmationByDate', date);
    }
    setShowDeadlineDatePicker(false);
  };

  const confirmationError =
    formikProps.errors.confirmationByDate || formikProps.errors.confirmationByTime;
  const hasConfirmationError =
    (formikProps.touched.confirmationByDate || formikProps.touched.confirmationByTime) &&
    !!confirmationError;

  return (
    <CreateOrderSectionContainer
      icon={<FontAwesomeIcon icon={faHeadset} />}
      title={intl.formatMessage({
        id: translationKeys.create_interpretation_order_manual_booking_title,
      })}
    >
      <div className={styles.manualBookingSection}>
        <span className={styles.label}>
          <FormattedMessage
            id={translationKeys.create_interpretation_order_manual_booking_actual_booking_receive}
          />
        </span>
        <div className={classNames(styles.flexRow, styles['flexRow--sm'])}>
          <div>
            <TextField
              label={intl.formatMessage({
                id: translationKeys.create_interpretation_order_manual_booking_date_label,
              })}
              placeholder={intl.formatMessage({
                id: translationKeys.create_interpretation_order_manual_booking_date_placeholder,
              })}
              icon={<FontAwesomeIcon icon={faCalendar} />}
              value={format(formikProps.values.actualCreatedAtDate, 'dd.MM.yyyy')}
              size="large"
              onFocus={() => setShowBookingDatePicker(true)}
              onChange={(e) =>
                e.target.value && formikProps.setFieldValue('actualCreatedAtDate', e.target.value)
              }
              type="search"
            />
            {showBookingDatePicker && (
              <DatePicker
                monthCount={1}
                onClose={() => setShowBookingDatePicker(false)}
                singleDate
                onChange={handleBookingInformationDate}
                selected={formikProps.values.actualCreatedAtDate}
              />
            )}
          </div>

          <div className={styles.timePicker}>
            <TimePicker
              label={intl.formatMessage({
                id: translationKeys.create_interpretation_order_manual_booking_time_label,
              })}
              placeholder="00:00"
              interval={1}
              selected={[formikProps.values.actualCreatedAtTime]}
              onChange={(item) => {
                if (item && item.length > 0) {
                  formikProps.setFieldValue('actualCreatedAtTime', item[0]);
                }
              }}
            />
          </div>
        </div>
        <div className={styles.flexRow}>
          <div className={styles.radioItem}>
            <B_Form.Check
              type="radio"
              label={intl.formatMessage({
                id: translationKeys.create_interpretation_order_manual_booking_phone_option,
              })}
              name="bookingMechanism"
              checked={formikProps.values.bookingMechanism === ManagerJobBookingMechanism.Phone}
              onChange={() =>
                formikProps.setFieldValue('bookingMechanism', ManagerJobBookingMechanism.Phone)
              }
            />
          </div>
          <div className={styles.radioItem}>
            <B_Form.Check
              type="radio"
              label={intl.formatMessage({
                id: translationKeys.create_interpretation_order_manual_booking_e_mail_option,
              })}
              name="bookingMechanism"
              checked={formikProps.values.bookingMechanism === ManagerJobBookingMechanism.Email}
              onChange={() =>
                formikProps.setFieldValue('bookingMechanism', ManagerJobBookingMechanism.Email)
              }
            />
          </div>
          <div className={styles.radioItem}>
            <B_Form.Check
              type="radio"
              label={intl.formatMessage({
                id: translationKeys.create_interpretation_order_manual_booking_chat_option,
              })}
              name="bookingMechanism"
              checked={formikProps.values.bookingMechanism === ManagerJobBookingMechanism.Chat}
              onChange={() =>
                formikProps.setFieldValue('bookingMechanism', ManagerJobBookingMechanism.Chat)
              }
            />
          </div>
          <div className={styles.radioItem}>
            <B_Form.Check
              type="radio"
              label={intl.formatMessage({
                id: translationKeys.create_interpretation_order_manual_booking_platform_option,
              })}
              name="bookingMechanism"
              checked={formikProps.values.bookingMechanism === ManagerJobBookingMechanism.Platform}
              onChange={() =>
                formikProps.setFieldValue('bookingMechanism', ManagerJobBookingMechanism.Platform)
              }
            />
          </div>
        </div>
        <hr />
        <span className={styles.label}>
          <FormattedMessage
            id={translationKeys.create_interpretation_order_manual_booking_manual_fees_question}
          />
        </span>
        <div className={styles.flexRow}>
          <div className={styles.radioItem}>
            <B_Form.Check
              type="checkbox"
              label={intl.formatMessage({
                id: translationKeys.create_interpretation_order_manual_booking_manual_booking_fee_option,
              })}
              name="manualBookingFeeApplied"
              checked={formikProps.values.manualBookingFeeApplied}
              onChange={(e) =>
                formikProps.setFieldValue('manualBookingFeeApplied', e.target.checked)
              }
            />
          </div>
          <div className={styles.radioItem}>
            <B_Form.Check
              type="checkbox"
              label={intl.formatMessage({
                id: translationKeys.create_interpretation_order_manual_booking_manual_editing_fee_option,
              })}
              name="manualEditingFeeApplied"
              checked={formikProps.values.manualEditingFeeApplied}
              onChange={(e) =>
                formikProps.setFieldValue('manualEditingFeeApplied', e.target.checked)
              }
            />
          </div>
          <div className={styles.radioItem}>
            <B_Form.Check
              type="checkbox"
              label={intl.formatMessage({
                id: translationKeys.create_interpretation_order_manual_booking_travel_booking_fee_option,
              })}
              name="manualTravelBookingFeeApplied"
              checked={formikProps.values.manualTravelBookingFeeApplied}
              onChange={(e) =>
                formikProps.setFieldValue('manualTravelBookingFeeApplied', e.target.checked)
              }
            />
          </div>
        </div>
        <hr />
        <span className={styles.label}>
          <FormattedMessage
            id={translationKeys.create_interpretation_order_manual_booking_confirmations}
          />
        </span>
        <div className={styles.radioItem}>
          <B_Form.Check
            data-testid="deadline-confirmation"
            type="checkbox"
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_manual_booking_set_deadline_checkbox,
            })}
            name="deadlineConfirmation"
            checked={formikProps.values.deadlineConfirmationActive}
            onChange={(e) =>
              formikProps.setFieldValue('deadlineConfirmationActive', e.target.checked)
            }
          />
        </div>
        {formikProps.values.deadlineConfirmationActive && (
          <div className={styles.flexRow}>
            <div>
              <TextField
                data-testid="confirmation-by-date"
                label={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_manual_booking_date_label,
                })}
                placeholder={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_manual_booking_date_placeholder,
                })}
                icon={<FontAwesomeIcon icon={faCalendar} />}
                value={
                  formikProps.values.confirmationByDate &&
                  format(formikProps.values.confirmationByDate, 'dd.MM.yyyy')
                }
                size="large"
                onFocus={() => setShowDeadlineDatePicker(true)}
                onChange={(e) => formikProps.setFieldValue('confirmationByDate', e.target.value)}
                type="search"
                isInvalid={hasConfirmationError}
              />
              {showDeadlineDatePicker && (
                <DatePicker
                  monthCount={1}
                  onClose={() => setShowDeadlineDatePicker(false)}
                  singleDate
                  onChange={handleDeadlineDate}
                  selected={formikProps.values.confirmationByDate}
                  minDate={new Date()}
                />
              )}
            </div>

            <div className={styles.timePicker}>
              <TimePicker
                data-testid="confirmation-by-time"
                label={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_manual_booking_time_label,
                })}
                placeholder="00:00"
                interval={1}
                selected={
                  formikProps.values.confirmationByTime && [formikProps.values.confirmationByTime]
                }
                onChange={(item) => {
                  if (item && item.length > 0) {
                    formikProps.setFieldValue('confirmationByTime', item[0]);
                  }
                }}
                isInvalid={hasConfirmationError}
              />
            </div>
          </div>
        )}
        {hasConfirmationError && (
          <Banner data-testid="deadline-banner" variant="error" text={confirmationError} />
        )}
        <hr />
        <div className={styles.flexRow}>
          <B_Form.Check
            type="switch"
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_manual_booking_auto_invite,
            })}
            checked={formikProps.values.manualBookingAutoInvite}
            onChange={(e) => formikProps.setFieldValue('manualBookingAutoInvite', e.target.checked)}
            disabled={
              formikProps.values.specificInterpreterActive &&
              !!formikProps.values.specificInterpreter
            }
          />
          <B_Form.Check
            type="switch"
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_manual_booking_standby,
            })}
            checked={formikProps.values.manualBookingStandBy}
            onChange={(e) => formikProps.setFieldValue('manualBookingStandBy', e.target.checked)}
          />
          <B_Form.Check
            type="switch"
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_manual_booking_auto_award,
            })}
            checked={formikProps.values.manualBookingAutoAward}
            onChange={(e) => formikProps.setFieldValue('manualBookingAutoAward', e.target.checked)}
          />
        </div>
      </div>
    </CreateOrderSectionContainer>
  );
};
