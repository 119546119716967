import React, { Fragment } from 'react';
import classNames from 'classnames';
import styles from './Stepper.module.scss';

interface StepperProps {
  steps: string[];
  currentStep: number;
  variant?: 'primary' | 'error' | 'closed';
}

const Stepper = ({ steps, currentStep, variant }: StepperProps) => {
  const isError = variant === 'error';
  const isClosed = variant === 'closed';
  const isCompleted = (i: number) => {
    return i + 1 <= currentStep;
  };

  return (
    <div className={styles.stepper}>
      {steps.map((step, i) => (
        <Fragment key={step}>
          <div
            className={classNames(
              styles.step,
              isCompleted(i) && styles['step--completed'],
              isError && styles['step--error'],
              isClosed && styles['step--closed'],
            )}
          >
            <span
              className={classNames(
                styles.stepNumber,
                isCompleted(i) && styles['stepNumber--completed'],
                isError && styles['stepNumber--error'],
                isClosed && styles['stepNumber--closed'],
              )}
            >
              {i + 1}
            </span>
            <span className={classNames(isCompleted(i) && styles['stepName--completed'])}>
              {step}
            </span>
          </div>
          {i !== steps.length - 1 && <div className={styles.stepLine} />}
        </Fragment>
      ))}
    </div>
  );
};

export default Stepper;
