import React from 'react';
import { IntlShape } from 'react-intl';
import { format } from 'date-fns';
import SystemLogDescriptionValues from '../../../../../components/SystemLog/SystemLogDescriptionValues';
import { SystemLogValues } from '../../../../../components/SystemLog/SystemLogValues';
import getAccessLevelName from '../../../../../InterpretationSkills/utils/getAccessLevelName';
import {
  ManagerOrderAccessLevel,
  ManagerOrderSourceType,
  ManagerOrderStatus,
  ManagerOrderSubtaskStatus,
  ManagerOrderSubtaskType,
  ManagerOrderTaskStatus,
} from '../../../../../types';
import ManagerOrderSystemLog, {
  ManagerOrderSystemLogType,
} from '../../../../../types/ManagerOrderSystemLog';
import getOrderSourceName from '../../../../utils/getOrderSourceName';
import getOrderStatusColour from '../../../../utils/getOrderStatusColour';
import getOrderStatusName from '../../../../utils/getOrderStatusName';
import getSubtaskStatusColour from '../../../../utils/getSubtaskStatusColour';
import getSubtaskStatusName from '../../../../utils/getSubtaskStatusName';
import getSubtaskTypeName from '../../../../utils/getSubtaskTypeName';
import getTaskStatusColour from '../../../../utils/getTaskStatusColour';
import getTaskStatusName from '../../../../utils/getTaskStatusName';
import { getAlternativeLanguageTranslation } from './getAlternativeLanguageTranslations';
import { getBooleanTranslation } from './getBooleanTranslations';
import { getDeliveryMethodTranslation } from './getDeliveryMethodTranslations';
import { getWaitingForCustomerTranslation } from './getWaitingForCustomerTranslations';

export const getValuesByType = (logItem: ManagerOrderSystemLog, intl: IntlShape) => {
  switch (logItem.logType) {
    case ManagerOrderSystemLogType.OrderUpdatedStatus:
      return (
        logItem.value && (
          <SystemLogValues
            oldValue={getOrderStatusName(logItem.oldValue as ManagerOrderStatus, intl)}
            value={getOrderStatusName(logItem.value as ManagerOrderStatus, intl)}
            oldValueTagColor={getOrderStatusColour(logItem.oldValue as ManagerOrderStatus)}
            valueTagColor={getOrderStatusColour(logItem.value as ManagerOrderStatus)}
          />
        )
      );
    case ManagerOrderSystemLogType.TaskUpdatedStatus:
      return (
        logItem.value && (
          <SystemLogValues
            oldValue={getTaskStatusName(logItem.oldValue as ManagerOrderTaskStatus, intl)}
            value={getTaskStatusName(logItem.value as ManagerOrderTaskStatus, intl)}
            oldValueTagColor={getTaskStatusColour(logItem.oldValue as ManagerOrderTaskStatus)}
            valueTagColor={getTaskStatusColour(logItem.value as ManagerOrderTaskStatus)}
          />
        )
      );
    case ManagerOrderSystemLogType.SubtaskUpdatedStatus:
      return (
        logItem.value && (
          <SystemLogValues
            oldValue={getSubtaskStatusName(logItem.oldValue as ManagerOrderSubtaskStatus, intl)}
            value={getSubtaskStatusName(logItem.value as ManagerOrderSubtaskStatus, intl)}
            oldValueTagColor={getSubtaskStatusColour(logItem.oldValue as ManagerOrderSubtaskStatus)}
            valueTagColor={getSubtaskStatusColour(logItem.value as ManagerOrderSubtaskStatus)}
          />
        )
      );
    case ManagerOrderSystemLogType.OrderAttachmentDownloaded:
    case ManagerOrderSystemLogType.OrderUpdatedWordCount:
    case ManagerOrderSystemLogType.OrderUpdatedCaseNumber:
    case ManagerOrderSystemLogType.OrderUpdatedBookerReference:
    case ManagerOrderSystemLogType.OrderUpdatedBookerDepartment:
    case ManagerOrderSystemLogType.OrderUpdatedClientName:
    case ManagerOrderSystemLogType.OrderUpdatedSendByPostRecipientName:
    case ManagerOrderSystemLogType.OrderUpdatedSendByPostAddress:
    case ManagerOrderSystemLogType.OrderUpdatedSendByPostEnterprise:
    case ManagerOrderSystemLogType.OrderUpdatedSendByPostCoverLetter:
    case ManagerOrderSystemLogType.OrderUpdatedSubject:
    case ManagerOrderSystemLogType.OrderUpdatedOrderReference:
    case ManagerOrderSystemLogType.OrderUpdatedClientNumber:
    case ManagerOrderSystemLogType.TaskUpdatedTargetLanguage:
    case ManagerOrderSystemLogType.SubtaskUpdatedSellerWordCount:
    case ManagerOrderSystemLogType.OrderUpdatedPaymentMethod:
    case ManagerOrderSystemLogType.OrderUpdatedOtherSubject:
    case ManagerOrderSystemLogType.OrderUpdatedLoggedOutPhoneNumber:
    case ManagerOrderSystemLogType.OrderUpdatedLoggedOutLastName:
    case ManagerOrderSystemLogType.OrderUpdatedLoggedOutBookerOrgNumber:
    case ManagerOrderSystemLogType.OrderUpdatedLoggedOutPaymentOrgNumber:
    case ManagerOrderSystemLogType.OrderUpdatedLoggedOutBookerOrgName:
    case ManagerOrderSystemLogType.OrderUpdatedLoggedOutBookerFirstName:
    case ManagerOrderSystemLogType.OrderUpdatedLoggedOutBookerEmail:
    case ManagerOrderSystemLogType.SubtaskUpdatedAssignedSeller:
    case ManagerOrderSystemLogType.SubtaskUpdatedAssignedQA:
    case ManagerOrderSystemLogType.OrderUpdatedBookerEnterprise:
    case ManagerOrderSystemLogType.SubtaskUpdatedTargetLanguage:
    case ManagerOrderSystemLogType.OrderUpdatedLoggedOutPhoneCode:
    case ManagerOrderSystemLogType.OrderUpdatedSourceLanguage:
    case ManagerOrderSystemLogType.SubtaskUpdatedSourceLanguage:
    case ManagerOrderSystemLogType.OrderUpdatedName:
      return logItem.value && <SystemLogValues oldValue={logItem.oldValue} value={logItem.value} />;
    case ManagerOrderSystemLogType.OrderUpdatedAlternativeLanguage:
      return (
        logItem.value && (
          <SystemLogValues
            oldValue={getAlternativeLanguageTranslation({ value: logItem.oldValue, intl })}
            value={getAlternativeLanguageTranslation({ value: logItem.value, intl })}
          />
        )
      );
    case ManagerOrderSystemLogType.OrderUpdatedDescription:
    case ManagerOrderSystemLogType.SubtaskUpdatedSalitaInstructions:
    case ManagerOrderSystemLogType.SubtaskUpdatedReviewComments:
    case ManagerOrderSystemLogType.OrderUpdatedInternalNote:
    case ManagerOrderSystemLogType.SubtaskUpdatedTranslatorComments:
    case ManagerOrderSystemLogType.OrderUpdatedDemanderFeedback:
      return (
        logItem.value && (
          <SystemLogDescriptionValues oldValue={logItem.oldValue} value={logItem.value} />
        )
      );
    case ManagerOrderSystemLogType.SubtaskUpdatedSellerQuote:
    case ManagerOrderSystemLogType.SubtaskUpdatedSalitaQuote:
      return (
        logItem.value && (
          <SystemLogValues oldValue={logItem.oldValue || '-'} value={logItem.value || '-'} />
        )
      );
    case ManagerOrderSystemLogType.OrderUpdatedAccessLevel:
      return (
        logItem.value && (
          <SystemLogValues
            oldValue={getAccessLevelName(logItem.oldValue as ManagerOrderAccessLevel, intl)}
            value={getAccessLevelName(logItem.value as ManagerOrderAccessLevel, intl)}
          />
        )
      );
    case ManagerOrderSystemLogType.OrderUpdatedSource:
      return (
        logItem.value && (
          <SystemLogValues
            oldValue={getOrderSourceName(logItem.oldValue as ManagerOrderSourceType, intl)}
            value={getOrderSourceName(logItem.value as ManagerOrderSourceType, intl)}
          />
        )
      );
    case ManagerOrderSystemLogType.OrderUpdatedWaitingForCustomer:
      if (logItem.oldValue === 'requested' && logItem.value === 'updated') return null;
      return (
        logItem.value && (
          <SystemLogValues
            oldValue={getWaitingForCustomerTranslation({ value: logItem.oldValue, intl })}
            value={getWaitingForCustomerTranslation({ value: logItem.value, intl })}
          />
        )
      );
    case ManagerOrderSystemLogType.OrderUpdatedSendByPost:
    case ManagerOrderSystemLogType.SubtaskUpdatedDeliveredShortNotice:
    case ManagerOrderSystemLogType.OrderUpdatedIsLoggedOut:
      return (
        logItem.value && (
          <SystemLogValues
            oldValue={getBooleanTranslation({ value: logItem.oldValue, intl })}
            value={getBooleanTranslation({ value: logItem.value, intl })}
          />
        )
      );
    case ManagerOrderSystemLogType.OrderUpdatedExternalDeadline:
    case ManagerOrderSystemLogType.SubtaskUpdatedInternalDeadline:
    case ManagerOrderSystemLogType.SubtaskUpdatedDeliveryDate:
      return (
        logItem.value && (
          <SystemLogValues
            oldValue={
              logItem.oldValue && logItem.oldValue !== '-'
                ? format(new Date(logItem.oldValue), 'dd.MM.yyyy')
                : '-'
            }
            value={logItem.value !== '-' ? format(new Date(logItem.value), 'dd.MM.yyyy') : '-'}
          />
        )
      );
    case ManagerOrderSystemLogType.SubtaskUpdatedType:
      return (
        logItem.value && (
          <SystemLogValues
            oldValue={getSubtaskTypeName(logItem.oldValue as ManagerOrderSubtaskType, intl)}
            value={getSubtaskTypeName(logItem.value as ManagerOrderSubtaskType, intl)}
          />
        )
      );
    case ManagerOrderSystemLogType.OrderUpdatedDeliveryMethod:
      return (
        logItem.value && (
          <SystemLogValues
            oldValue={getDeliveryMethodTranslation({ value: logItem.oldValue, intl })}
            value={getDeliveryMethodTranslation({ value: logItem.value, intl })}
          />
        )
      );

    // TODO: handle these logs values after BE fixes
    case ManagerOrderSystemLogType.OrderUpdatedCCEmails:
    case ManagerOrderSystemLogType.OrderUpdatedCoverLetters:
    case ManagerOrderSystemLogType.OrderUpdatedRequestedShortNotice:
    default:
      return null;
  }
};
