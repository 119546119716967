import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import {
  faArrowRightArrowLeft,
  faBuilding,
  faClone,
  faEnvelope,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Button,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
  Tooltip,
} from '@skiwo/components';
import classNames from 'classnames';
import { FormikProps } from 'formik';
import CreateOrderSectionContainer from '../../components/CreateOrderSectionContainer/CreateOrderSectionContainer';
import { CustomerShape } from '../../components/SearchCustomerSection/SearchCustomerSection';
import CreateDepartmentDrawer from '../../Drawers/CreateDepartmentDrawer/CreateDepartmentDrawer';
import { useApi } from '../../providers/ApiProvider';
import translationKeys from '../../translations/translationKeys';
import { EnterpriseDepartment } from '../../types';
import { CreateTranslationOrderFormValues } from '../CreateTranslationOrder';
import { ConfimChangeCustomerModal } from './ConfimChangeCustomerModal/ConfimChangeCustomerModal';
import styles from './CustomerSection.module.scss';

interface CustomerSectionProps {
  customer: CustomerShape;
  changeCustomer: () => void;
  selectedDepartment?: SearchDropdownMenuOption;
  setSelectedDepartment: (value: SearchDropdownMenuOption) => void;
  setDepartmentList: (value: EnterpriseDepartment[]) => void;
  formikProps: FormikProps<CreateTranslationOrderFormValues>;
}

const CustomerSection = ({
  customer,
  changeCustomer,
  selectedDepartment,
  setSelectedDepartment,
  setDepartmentList,
  formikProps,
}: CustomerSectionProps) => {
  const [showCreateDepartmentDrawer, setShowCreateDepartmentDrawer] = useState(false);
  const [showChangeCustomerModal, setShowChangeCustomerModal] = useState(false);
  const [departmentOptions, setDepartmentOptions] = useState<SearchDropdownMenuOption[]>([]);
  const api = useApi();
  const intl = useIntl();

  const getEnterpriseDepartments = async () => {
    if (!customer.enterprise?.id) return;

    const { data } = await api.getDepartments(customer.enterprise.id.toString(), true);

    if (data) {
      setDepartmentList(data.departments);
      return data.departments;
    }
  };

  const getDepartmentOptions = async () => {
    const enterpriseDepartments = await getEnterpriseDepartments();

    const customerDepartmentOptions =
      customer.departments?.map((department) => ({
        id: department.id,
        key: department.id.toString(),
        label: department.name,
        group: intl.formatMessage({
          id: translationKeys.create_translation_order_customer_departments_group,
        }),
      })) || [];
    const enterpriseDepartmentOptions =
      enterpriseDepartments
        ?.filter(
          (department) => !customerDepartmentOptions.find((item) => item.id === department.id),
        )
        .map((department) => ({
          id: department.id,
          key: department.id.toString(),
          label: department.name,
          group: intl.formatMessage({
            id: translationKeys.create_translation_order_all_departments_group,
          }),
        })) || [];

    if (customerDepartmentOptions.length) {
      formikProps.setFieldValue('departmentId', customerDepartmentOptions[0]?.id.toString());
      setSelectedDepartment(customerDepartmentOptions[0]);
    }
    setDepartmentOptions([...customerDepartmentOptions, ...enterpriseDepartmentOptions]);
  };

  useEffect(() => {
    getDepartmentOptions();
  }, []);

  return (
    <>
      <CreateOrderSectionContainer
        icon={<FontAwesomeIcon icon={faBuilding} />}
        title={intl.formatMessage({ id: translationKeys.create_translation_order_customer_label })}
        action={
          <Button
            variant="link"
            data-testid="change-customer-button"
            icon={<FontAwesomeIcon icon={faArrowRightArrowLeft} />}
            size="large"
            onClick={() => setShowChangeCustomerModal(true)}
          >
            <FormattedMessage
              id={translationKeys.create_translation_order_change_customer_button}
            />
          </Button>
        }
        data-testid="customer-section"
        marginSize="sm"
      >
        <div className={styles.customerDetails}>
          <div className={styles.row}>
            <div className={styles.infoItem}>
              {customer.isShared ? (
                <div className={styles.input}>
                  <TextField
                    name="bookerName"
                    size="large"
                    placeholder={intl.formatMessage({
                      id: translationKeys.create_translation_order_customer_booker_name_placeholder,
                    })}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.bookerName}
                    errorText={
                      formikProps.touched.bookerName ? formikProps.errors.bookerName : undefined
                    }
                    data-testid="customer-booker-name"
                  />
                </div>
              ) : (
                <div className={styles.data}>
                  <span className={styles.title}>
                    <FormattedMessage
                      id={translationKeys.create_translation_order_customer_name_label}
                    />
                  </span>
                  <span className={styles.value} data-testid="customer-name">
                    {customer.name}
                  </span>
                </div>
              )}
            </div>
            <div className={styles.infoItem}>
              <div className={styles.data}>
                <span className={styles.title}>
                  <FormattedMessage
                    id={translationKeys.create_translation_order_customer_email_label}
                  />
                </span>
                <span className={styles.value} data-testid="customer-email">
                  {customer.email}
                </span>
              </div>
              {customer.email && (
                <div className={styles.iconWrapper}>
                  <Tooltip title={customer.email} copyable>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </Tooltip>
                </div>
              )}
            </div>
            <div className={styles.infoItem}>
              <div className={styles.data}>
                <span className={styles.title}>
                  <FormattedMessage
                    id={translationKeys.create_translation_order_customer_phone_label}
                  />
                </span>
                <span className={styles.value} data-testid="customer-phone">
                  {customer.phone || '-'}
                </span>
              </div>
              {customer.phone && (
                <div className={styles.iconWrapper}>
                  <Tooltip title={customer.phone} copyable>
                    <FontAwesomeIcon icon={faClone} />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
          <hr />
          <div className={styles.row}>
            <div className={styles.infoItem}>
              <div className={styles.data}>
                <span className={styles.title}>
                  <FormattedMessage
                    id={translationKeys.create_translation_order_customer_enterprise_label}
                  />
                </span>
                <span className={styles.value} data-testid="customer-enterprise-name">
                  {customer.enterprise?.name || '-'}
                </span>
              </div>
              {customer.enterprise?.logoUrl && (
                <Avatar
                  size="medium"
                  altText={customer.enterprise?.name || ''}
                  url={customer.enterprise.logoUrl}
                />
              )}
            </div>
            <div className={classNames(styles.departmentInfoItem)}>
              <div className={styles.row}>
                {!!departmentOptions.length && (
                  <div className={styles.departmentSearch} data-testid="department-dropdown">
                    <SearchDropdown
                      size="large"
                      data-testid="department-option-dropdown"
                      options={departmentOptions}
                      placeholder={intl.formatMessage({
                        id: translationKeys.create_translation_order_department_placeholder,
                      })}
                      grouped
                      selected={selectedDepartment ? [selectedDepartment] : []}
                      hideClearSelected
                      onChange={(item) => {
                        if (item && item.length > 0 && item[0].key) {
                          const selectedDepartment = item[0];
                          setSelectedDepartment(selectedDepartment);

                          formikProps.setFieldValue(
                            'departmentId',
                            selectedDepartment.id.toString(),
                          );
                        }
                      }}
                      isInvalid={
                        formikProps.touched.departmentId ? !!formikProps.errors.departmentId : false
                      }
                    />
                  </div>
                )}
                <Button
                  variant="gray"
                  size="x-large"
                  data-testid="add-department-button"
                  onClick={() => setShowCreateDepartmentDrawer(true)}
                  className={classNames(!departmentOptions.length && styles.button)}
                >
                  <FontAwesomeIcon icon={faPlus} />{' '}
                  {!departmentOptions.length && (
                    <span>
                      <FormattedMessage
                        id={translationKeys.create_translation_order_customer_add_department_button}
                      />
                    </span>
                  )}
                </Button>
              </div>
              {formikProps.errors.departmentId && formikProps.touched.departmentId && (
                <span className={styles.errorMessage}>
                  <span>
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                  </span>
                  {formikProps.errors.departmentId}
                </span>
              )}
            </div>
          </div>
        </div>
      </CreateOrderSectionContainer>

      <CreateDepartmentDrawer
        show={showCreateDepartmentDrawer}
        onClose={() => setShowCreateDepartmentDrawer(false)}
        onComplete={() => {
          getDepartmentOptions();
        }}
      />

      <ConfimChangeCustomerModal
        show={showChangeCustomerModal}
        onClose={() => setShowChangeCustomerModal(false)}
        changeCustomer={() => {
          changeCustomer();
          formikProps.resetForm();
        }}
      />
    </>
  );
};

export default CustomerSection;
