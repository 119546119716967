import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Drawer, Tabs } from '@skiwo/components';
import translationKeys from '../../../translations/translationKeys';
import {
  JobDetailsDrawerName,
  useJobDetailsDrawer,
} from '../JobDetailsDrawerContext/JobDetailsDrawerContext';
import InvitationLogTabSection from './InvitationLogTabSection/InvitationLogTabSection';
import SystemLogTabSection from './SystemLogTabSection';
import styles from './SystemLogDrawer.module.scss';

export enum SystemLogTab {
  SystemLog = 'system-log',
  InvitationLog = 'invitation-log',
}

const SystemLogDrawer = () => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState<SystemLogTab>(SystemLogTab.SystemLog);
  const { isDrawerOpen, closeDrawer } = useJobDetailsDrawer();

  const isDrawerOpened = isDrawerOpen(JobDetailsDrawerName.SystemLogDrawer);
  const handleCloseDrawer = () => closeDrawer(JobDetailsDrawerName.SystemLogDrawer);

  return (
    <Drawer
      show={isDrawerOpened}
      title={intl.formatMessage({ id: translationKeys.job_system_log_label })}
      onClose={handleCloseDrawer}
    >
      <div className={styles.systemLogDrawer}>
        <div className={styles.tabs}>
          <Tabs
            items={[
              {
                id: SystemLogTab.SystemLog,
                title: intl.formatMessage({ id: translationKeys.job_system_log_label }),
                active: activeTab === SystemLogTab.SystemLog,
              },
              {
                id: SystemLogTab.InvitationLog,
                title: intl.formatMessage({
                  id: translationKeys.job_system_log_invitation_log_label,
                }),
                active: activeTab === SystemLogTab.InvitationLog,
              },
            ]}
            onSelect={(activeTab) => {
              setActiveTab(activeTab as SystemLogTab);
            }}
          />
        </div>
        {activeTab === SystemLogTab.SystemLog && <SystemLogTabSection />}
        {activeTab === SystemLogTab.InvitationLog && <InvitationLogTabSection />}
      </div>
    </Drawer>
  );
};

export default SystemLogDrawer;
