import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Banner,
  Button,
  Drawer,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
} from '@skiwo/components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { emailRegex } from '../../helpers/regexPatterns';
import useDebounce from '../../hooks/useDebounce';
import { useApi } from '../../providers/ApiProvider';
import { useToast } from '../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../translations/translationKeys';
import { PaymentMethod } from '../../types/EnterpriseDepartment';
import styles from './CreatePaymentDrawer.module.scss';

interface FormValues {
  orgNumber: string;
  email?: string;
  default: boolean;
  departmentIds: string[];
}

interface Props {
  show?: boolean;
  onClose: () => void;
  customerName?: string;
  enterpriseId?: string;
  enterpriseName?: string;
}

enum EnterpriseType {
  ExistingEnterprises = 'Existing Enterprises',
  BrregEnterprise = 'Results From Brreg.no',
}

const CreatePaymentDrawer = ({
  show,
  onClose,
  customerName,
  enterpriseName,
  enterpriseId,
}: Props) => {
  const api = useApi();
  const [enterpriseOptions, setEnterpriseOptions] = useState<SearchDropdownMenuOption[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState<SearchDropdownMenuOption[]>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<SearchDropdownMenuOption[]>([]);
  const [defaultForDepartments, setDefaultForDepartments] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const intl = useIntl();
  const { showErrorToast } = useToast();
  const debounceEnterpriseSearch = useDebounce(300);

  const schema = yup.object().shape({
    orgNumber: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.create_payment_method_drawer_enterprise_label,
          }),
        },
      ),
    ),
    email: yup.string().matches(
      emailRegex,
      intl.formatMessage(
        { id: translationKeys.form_error_not_valid },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.create_payment_method_drawer_invoice_email_label,
          }),
        },
      ),
    ),
  });

  const handleSubmit = async (values: FormValues) => {
    if (!enterpriseId) return;
    const { error } = await api.createPaymentMethod(enterpriseId.toString(), values, setIsLoading);

    if (error) {
      showErrorToast(error);
    } else {
      onClose();
    }
  };

  const getDepartmentSubtitle = (paymentMethod?: PaymentMethod | null) => {
    if (!paymentMethod)
      return intl.formatMessage({
        id: translationKeys.create_payment_method_drawer_no_default_label,
      });

    if (paymentMethod?.orgNumber) {
      return `${paymentMethod?.orgName || '-'} / ${paymentMethod?.orgNumber}`;
    } else {
      return `${paymentMethod?.email || '-'} / Email`;
    }
  };

  const getEnterpriseDepartments = async (enterpriseId?: string) => {
    if (!enterpriseId) return;

    const { data } = await api.getDepartments(enterpriseId, true);

    if (data) {
      setDepartmentOptions(
        data.departments.map((department) => ({
          id: department.id,
          label: department.name,
          subtitle: getDepartmentSubtitle(department.paymentMethod),
          key: department.id.toString(),
        })),
      );
      return data.departments;
    }
  };

  const getEnterpriseList = async (query: string) => {
    const { data } = await api.searchEnterprises({
      query,
      search_brreg: true,
    });

    if (data) {
      const enterpriseOptions =
        data.enterprises.map((enterprise) => {
          return {
            id: enterprise.id,
            label: enterprise.name,
            key: enterprise.orgNumber,
            subtitle: `Org. nr. ${enterprise.orgNumber}`,
            group: EnterpriseType.ExistingEnterprises,
          };
        }) || [];

      const brregOptions =
        data.brregSearch.map((enterprise) => {
          return {
            id: parseInt(enterprise.orgNumber),
            label: enterprise.name,
            key: enterprise.orgNumber,
            subtitle: `Org. nr. ${enterprise.orgNumber}`,
            group: EnterpriseType.BrregEnterprise,
          };
        }) || [];

      setEnterpriseOptions([...enterpriseOptions, ...brregOptions]);
    }
  };

  useEffect(() => {
    if (!show) return;
    if (!enterpriseId) return;

    getEnterpriseDepartments(enterpriseId.toString());
  }, [show]);

  return (
    <Drawer
      show={show}
      onClose={onClose}
      title={intl.formatMessage({ id: translationKeys.create_payment_method_drawer_label })}
      data-testid="send-saved-responses-drawer"
    >
      <Formik<FormValues>
        initialValues={{
          orgNumber: '',
          email: undefined,
          default: false,
          departmentIds: [],
        }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ handleSubmit, handleChange, handleBlur, values, setFieldValue, touched, errors }) => (
          <Form className={styles.createPaymentMethod} onSubmit={handleSubmit}>
            <div className={styles.overview}>
              <span className={styles.id}>
                <FormattedMessage id={translationKeys.create_payment_method_drawer_customer_type} />
              </span>
              <div className={styles.title}>
                <span>{customerName}</span>
              </div>
              <span className={styles.description}>{enterpriseName || '-'}</span>
            </div>

            <div data-testid="enterprise-dropdown">
              <SearchDropdown
                options={enterpriseOptions}
                label={intl.formatMessage({
                  id: translationKeys.create_payment_method_drawer_enterprise_label,
                })}
                placeholder={intl.formatMessage({
                  id: translationKeys.create_payment_method_drawer_enterprise_placeholder,
                })}
                size="large"
                search
                grouped
                required
                onSearch={(query: string) => {
                  debounceEnterpriseSearch(() => {
                    getEnterpriseList(query);
                  });
                }}
                onChange={(enterprise) => {
                  if (enterprise && enterprise.length > 0 && enterprise[0].key) {
                    setFieldValue('orgNumber', enterprise[0].key);
                  }
                }}
                errorText={touched.orgNumber ? errors.orgNumber : undefined}
              />
            </div>

            <Banner
              text={intl.formatMessage({
                id: translationKeys.create_payment_method_drawer_info_banner,
              })}
              variant="information"
            />

            <TextField
              name="email"
              label={intl.formatMessage({
                id: translationKeys.create_payment_method_drawer_invoice_email_label,
              })}
              placeholder={intl.formatMessage({
                id: translationKeys.create_payment_method_drawer_invoice_email_placeholder,
              })}
              size="large"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              errorText={touched.email ? errors.email : undefined}
            />

            <div className={styles.checkboxesWrapper}>
              <Form.Check
                type="checkbox"
                onChange={(e) => {
                  setFieldValue('default', e.target.checked);
                }}
                checked={values.default}
                label={intl.formatMessage(
                  {
                    id: translationKeys.create_payment_method_drawer_set_default_for_enterprise_checkbox,
                  },
                  { enterpriseName: enterpriseName },
                )}
                className={styles.noSourceLanguageCheckbox}
              />

              {!!departmentOptions.length && (
                <>
                  <Form.Check
                    type="checkbox"
                    onChange={(e) => {
                      setFieldValue('departmentIds', []);
                      setDefaultForDepartments(e.target.checked);
                    }}
                    checked={defaultForDepartments}
                    label={intl.formatMessage({
                      id: translationKeys.create_payment_method_drawer_set_default_for_departments_checkbox,
                    })}
                    className={styles.noSourceLanguageCheckbox}
                  />
                  {defaultForDepartments && (
                    <SearchDropdown
                      size="large"
                      options={departmentOptions}
                      placeholder={intl.formatMessage({
                        id: translationKeys.create_payment_method_drawer_departments_placeholder,
                      })}
                      multiple
                      selected={selectedDepartments}
                      hideClearSelected={true}
                      onChange={(selectedDepartments) => {
                        if (selectedDepartments && selectedDepartments.length > 0) {
                          setSelectedDepartments(selectedDepartments);

                          setFieldValue(
                            'departmentIds',
                            selectedDepartments.map((item) => item.id),
                          );
                        }
                      }}
                    />
                  )}
                </>
              )}
            </div>

            <div className={styles.actions}>
              <Button
                size="large"
                variant="gray"
                data-testid="clear-button"
                onClick={() => {
                  onClose();
                }}
              >
                <FormattedMessage id={translationKeys.create_payment_method_drawer_cancel_button} />
              </Button>

              <Button size="large" type="submit" data-testid="submit-button" isLoading={isLoading}>
                <FormattedMessage id={translationKeys.create_payment_method_drawer_add_button} />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default CreatePaymentDrawer;
