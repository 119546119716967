export enum JobSystemLogType {
  JobUpdated = 'job_updated',
  JobUdatedFeesAndOrder = 'job_updated_fees_and_order',
  JobUpdatedExpenses = 'job_updated_expenses',
  JobUpdatedInvoicing = 'job_updated_invoicing',
  JobUpdatedAssignmentType = 'job_updated_assignment_type',
  JobUpdatedReferences = 'job_updated_references',
  SupplierFeedbackUpdated = 'supplier_feedback_updated',
  SupplierFeedbackUpdatedAssignment = 'supplier_feedback_updated_assignment',
  SupplierFeedbackUpdatedInterpreter = 'supplier_feedback_updated_interpreter',
  SupplierFeedbackUpdatedAssignmentDuration = 'supplier_feedback_updated_assignment_duration',
  SupplierFeedbackUpdatedJobCompleted = 'supplier_feedback_updated_job_completed',
  SupplierFeedbackUpdatedShowedUp = 'supplier_feedback_updated_showed_up',
  SupplierFeedbackUpdatedPublicFeedback = 'supplier_feedback_updated_public_feedback',
  SupplierFeedbackUpdatedJobCancelled = 'supplier_feedback_updated_job_cancelled',
  DemanderFeedbackUpdated = 'demander_feedback_updated',
  DemanderFeedbackUpdatedAssignment = 'demander_feedback_updated_assignment',
  DemanderFeedbackUpdatedInterpreter = 'demander_feedback_updated_interpreter',
  DemanderFeedbackUpdatedAssignmentDuration = 'demander_feedback_updated_assignment_duration',
  DemanderFeedbackUpdatedJobCompleted = 'demander_feedback_updated_job_completed',
  DemanderFeedbackUpdatedShowedUp = 'demander_feedback_updated_showed_up',
  DemanderFeedbackUpdatedProblems = 'demander_feedback_updated_problems',
  DemanderFeedbackUpdatedPublicFeedback = 'demander_feedback_updated_public_feedback',
  JobCreated = 'job_created',
  JobPublished = 'job_published',
  JobCancelled = 'job_cancelled',
  JobClosed = 'job_closed',
  JobInProgress = 'job_in_progress',
  JobFinished = 'job_finished',
  JobLocked = 'job_locked',
  JobPricingTemplateUpdated = 'job_pricing_template_updated',
  JobPricingTemplateSwapped = 'job_pricing_template_swapped',
  JobPaymentMethodAdminSet = 'job_payment_method_admin_set',
  JobHighDemandFlowActivated = 'job_high_demand_flow_activated',
  //?
  ApplicationInvited = 'application_invited',
  ApplicationAccepted = 'application_accepted',
  ApplicationAcceptedViaStandby = 'application_accepted_via_standby',
  ApplicationApplied = 'application_applied',
  ApplicationAppliedViaStandby = 'application_applied_via_standby',
  ApplicationWithdrawn = 'application_withdrawn',
  ApplicationDeclinedByDemander = 'application_declined_by_demander',
  ApplicationDeclinedBySupplier = 'application_declined_by_supplier',
  ApplicationRejected = 'application_rejected',
  FeedbackCreated = 'feedback_created',
  FeedbackUpdated = 'feedback_updated',
  FeedbackConfirmed = 'feedback_confirmed',
  JobStatementCreated = 'job_statement_created',
  JobStatementUpdated = 'job_statement_updated',
  JobNotification = 'job_notification',
  LangPairHighDemandFlowActivated = 'lang_pair_high_demand_flow_activated',
  ActivatedAlternativeLanguage = 'activated_alternative_language',
  DowngradedQualification = 'downgraded_qualification',
  DowngradedQualificationForcefully = 'downgraded_qualification_forcefully',
  UpdatedAlternativeLanguage = 'updated_alternative_language',
  UpdatedGender = 'updated_gender',
  UpdatedGenderForcefully = 'updated_gender_forcefully',
  UpdatedSessionType = 'updated_session_type',
  UpdatedSessionTypeForcefully = 'updated_session_type_forcefully',
  JobReadyForFinanceEnabled = 'job_ready_for_finance_set',
  JobReadyForFinanceDisabled = 'job_not_ready_for_finance_set',

  // Notification types
  AnnounceAwardTo = 'announce_award_to',
  AnnounceDeclinationBySupplier = 'announce_declination_by_supplier_to',
  AnnounceDeclinationByDemander = 'nnounce_declination_by_demander_to',
  AnnounceInvitationTo = 'announce_invitation_to',
  AnnounceRejectionTo = 'announce_rejection_to',
  AnnounceWithdrawalTo = 'announce_withdrawal_to',
  AnnounceAdminCancellationTo = 'announce_admin_cancellation_to',
  AnnounceFeedbackForCancelledJobTo = 'announce_feedback_for_cancelled_job_to',
  UpdateAttendeeListWith = 'update_attendee_list_with',
  AnnounceCancellationTo = 'announce_cancellation_to',
  AnnounceCancellationSmsTo = 'announce_cancellation_sms_to',
  AnnounceJobChangesTo = 'announce_job_changes_to',
  SmsAnnounceAwardTo = 'sms_announce_award_to',
  RequestFeedbackFrom = 'request_feedback_from',
  RatesChanged = 'rates_changed',
  LogoutSupport = 'logout_support',
  AnnounceSessionDetailsTo = 'announce_session_details_to',
  NotShown = 'not_shown',
  AnnouncePostedJobTo = 'announce_posted_job_to',
  AnnouncePublicFeedbackTo = 'announce_public_feedback_to',
  RequestWithdrawFromJob = 'request_withdraw_from_job',
  RequestCancelJob = 'request_cancel_job',
  RequestFinishConfirmationFromDemander = 'request_finish_confirmation_from_demander',
  RequestInPersonFeedbackFrom = 'request_in_person_feedback_from',
  AnnounceFeedbackTo = 'announce_feedback_to',
  AnnouncePostedJobToSupplier = 'announce_posted_job_to_supplier',
  AnnounceNewPhoneNumberToDemander = 'announce_new_phone_number_to_demander',
  RemindThe = 'remind_the',

  // Dynamic types
  JobUpdatedLanguage = 'job_updated_interpretation_requirement_language_to_id',
  JobUpdatedDialect = 'job_updated_interpretation_requirement_dialect',
  JobUpdatedStartTime = 'job_updated_interpretation_requirement_start_time',
  JobUpdatedFinishTime = 'job_updated_interpretation_requirement_finish_time',
  //?
  JobUpdatedCategory = 'job_updated_info_job_category_item_id',
  JobUpdatedSubject = 'job_updated_info_subject',
  // JobUpdatedAssignmentType = 'job_updated_assignment_type',
  JobUpdatedContactPerson = 'job_updated_contact_name',
  JobUpdatedContactPhone = 'job_updated_contact_phone',
  JobUpdatedAddress = 'job_updated_contact_address',
  JobUpdatedAlternativeType = 'job_updated_process_requirement_auto_update_to_session_type',
  JobUpdatedAlternativeLanguage = 'job_updated_interpretation_requirement_alternative_language_to_id',
  JobUpdatedSpecificGender = 'job_updated_interpretation_requirement_sex',
  JobUpdatedQualification = 'job_updated_interpretation_requirement_qualification_id',
  JobUpdatedBlockedInterpreters = 'job_updated_interpretation_requirements_blocked_interpreters',
  JobUpdatedBlockedCities = 'job_updated_interpretation_requirements_blocked_cities',
  JobUpdatedMessageToInterpreter = 'job_updated_info_description',
  JobUpdatedUploadFiles = 'job_updated_attachments',

  JobUpdatedBookerName = 'job_updated_info_owner',
  JobUpdatedAttendees = 'job_updated_participants',
  JobUpdatedCCEmails = 'job_updated_info_cc_emails',
  JobUpdatedCCPhone = 'job_updated_info_confirmation_phones',

  //?
  JobUpdatedPaymentMethod = 'job_updated_payment_method_id',
  JobUpdatedBookerReference = 'job_updated_info_booking_reference',
  JobUpdatedOrderReference = 'job_updated_order_reference',
  JobUpdatedCaseNumber = 'job_updated_case_number',
  JobUpdatedClientNumber = 'job_updated_client_number',
  JobUpdatedClientName = 'job_updated_client_name',
  JobUpdatedCustomerPricingTemplate = 'job_updated_demander_pricing_template_id',
  JobUpdatedSupplierHonorar = 'job_updated_supplier_honorar_pricing_template_id',
  JobUpdatedSupplierCompanyPricingTemplate = 'job_updated_supplier_company_pricing_template_id',
  JobUpdatedSignedContractId = 'job_updated_signed_contract_id',

  JobUpdatedManualBookingDate = 'job_updated_actual_created_at',
  JobUpdatedManualBookingMethod = 'job_updated_booking_mechanism',
  JobUpdatedManualBookingFeeApplied = 'job_updated_info_manual_booking_fee_applied',
  JobUpdatedManualEditingFeeApplied = 'job_updated_info_manual_editing_fee_applied',
  JobUpdatedManualTravelBookingFeeApplied = 'job_updated_info_manual_travel_booking_fee_applied',
  JobUpdatedManualBookingConfirmationDeadline = 'job_updated_interpretation_requirement_confirmation_by',

  JobUpdatedAutoAward = 'job_updated_allow_auto_award',
  JobUpdatedAutoAwardAllowed = 'job_updated_auto_award_allowed',
  JobUpdatedProactive = 'job_updated_proactive',
  JobUpdatedStandby = 'job_updated_process_requirement_standby_processing',
  JobUpdatedAutoInvite = 'job_updated_process_requirement_auto_invite_enabled',
  JobUpdatedAutoInviteSpeed = 'job_updated_process_requirement_invitation_delay',

  JobUpdatedInterpretationRequirementSessionType = 'job_updated_interpretation_requirement_session_type',
  JobUpdatedInterpretationRequirementDepartmentId = 'job_updated_interpretation_requirement_department_id',
  JobUpdatedProcessRequirementSessionTypeProcessing = 'job_updated_process_requirement_session_type_processing',
  JobUpdatedProcessRequirementGenderProcessing = 'job_updated_process_requirement_gender_processing',
  JobUpdatedProcessRequirementAlternativeLanguageProcessing = 'job_updated_process_requirement_alternative_language_processing',
  JobUpdatedProcessRequirementQualificationProcessing = 'job_updated_process_requirement_qualification_processing',
  JobUpdatedProcessRequirementAutoUpdateToSessionType = 'job_updated_process_requirement_auto_update_to_session_type',
  JobUpdatedProcessRequirementNextInvitationTime = 'job_updated_process_requirement_next_invitation_time',
  JobUpdatedProcessRequirementAutoInviteStatus = 'job_updated_process_requirement_auto_invite_status',
  JobUpdatedInterpretationRequirementSpecificQualificationRequested = 'job_updated_interpretation_requirement_specific_qualification_requested',
}

export enum SystemLogActionType {
  action = 'action',
  notification = 'notification',
}

export enum SystemLogActorRole {
  Demander = 'demander',
  Supplier = 'supplier',
  Admin = 'admin',
  System = 'system',
  Unknown = 'unknown',
  Manager = 'manager',
}

interface JobSystemLog {
  logType: JobSystemLogType;
  createdAt: string;
  actorRole: SystemLogActorRole;
  loggedActionType: SystemLogActionType;
  context?: string;
  oldValue?: string;
  value?: string;
  recipient?: {
    name?: string;
    avatar?: string;
  };
  actor?: {
    name?: string;
    avatar?: string;
  };
  adminActor?: {
    name?: string;
    avatar?: string;
  };
}

export interface JobSystemLogResponse {
  collection: JobSystemLog[];
  pagination: {
    count: number;
    page: number;
    pages: number;
  };
}

export default JobSystemLog;
