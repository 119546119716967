import { ManagerOrderSubtaskType } from './ManagerOrder';
import { ManagerSuitableSeller } from './ManagerSuitableSeller';

export enum ManagerOrderSubtaskStatus {
  Draft = 'draft',
  ProactiveAccess = 'proactive_access',
  Invited = 'invited',
  Accepted = 'accepted',
  Rejected = 'rejected',
  InProgress = 'in_progress',
  ReviewRequested = 'review_requested',
  ChangesRequired = 'changes_required',
  Finished = 'finished',
  Cancelled = 'cancelled',
}

export enum ManagerOrderSubtaskDeadlineRequestStatus {
  Approved = 'approved',
  Rejected = 'rejected',
  Pending = 'pending',
}

export enum ManagerOrderSubtaskPayoutStatus {
  NotReadyForFinance = 'not_ready_for_finance',
  ReadyForFinance = 'ready_for_finance',
  PayoutDownloaded = 'payout_downloaded',
}

export interface ManagerOrderDeadlineRequest {
  id: number;
  message: string | null;
  status: ManagerOrderSubtaskDeadlineRequestStatus;
  createdAt: string;
  originalInternalDeadline: string;
  requestedInternalDeadline: string;
}

interface ManagerOrderSubtaskDetail {
  id: number;
  caseNumber?: string;
  taskId: number;
  sellerId: string | null;
  status: ManagerOrderSubtaskStatus;
  subtaskType: ManagerOrderSubtaskType;
  countOfWords?: number;
  instructionsFromProjectManagerToSeller?: string;
  reviewCommentFromSeller?: string;
  internalDeadline?: string;
  instructionsFromDemander?: string;
  externalDeadline?: string;
  reviewRequestedAt?: string;
  platformQuoteAmount?: number;
  sellerQuoteAmount?: number;
  acceptedEmailSent: boolean;
  cancelledEmailSent: boolean;
  changesRequiredEmailSent: boolean;
  sequenceNumber: number;
  reviewCommentFromProjectManager?: string;
  invitedEmailSent: boolean;
  internalNote?: string;
  qualityAssurerUid: string | null;
  sourceLanguageId: number;
  targetLanguageId: number;
  payoutStatus: ManagerOrderSubtaskStatus;
  lastInvitedAt?: string;
  publicId: string;
  autoRejectEnabled: boolean;
  assignedManagerUid?: string;
  deliveredShortNotice?: boolean;
  maxInternalDeadline?: string;
  demanderRatePerWord?: number;
  demanderMinChargeAmount?: number;
  demanderIncludedWords?: number;
  supplierRatePerWord?: number;
  supplierMinChargeAmount?: number;
  supplierIncludedWords?: number;
  demanderPricingTemplateId?: number;
  supplierPricingTemplateId?: number;
  translationMethodId?: number;
  deliveredOn?: string;
  createdAt: string;
  updatedAt: string;
  closedAt?: string;
  archived: boolean;
  enterprise?: {
    id?: number;
    name?: string;
    orgNumber?: string;
    avatar?: string;
    isPolice?: boolean;
  };
  owner?: {
    name?: string;
    phone?: string;
    email: string;
    uid: string;
    avatar?: string;
  };
  translator?: ManagerSuitableSeller;
  assignedManager?: {
    uid: string;
    name: string;
    avatar?: string;
    email: string;
    phone?: string;
  };
  qualityAssurer?: {
    uid: string;
    name: string;
    avatar?: string;
    email: string;
    phone?: string;
  };
  emails?: {
    cancelledEmailSentAt: string;
    acceptedEmailSentAt?: string;
    invitedEmailSentAt?: string;
    changesRequiredEmailSentAt?: string;
  };
  deadlineChangeRequest?: ManagerOrderDeadlineRequest;
  settings: {
    defaultExternalDeadlineDaysNumber: number;
    defaultInternalDeadlineDaysNumber: number;
  };
}

export interface ManagerOrderSubtaskResponse {
  subtask: ManagerOrderSubtaskDetail;
}

export default ManagerOrderSubtaskDetail;
