import React from 'react';
import { IntlShape } from 'react-intl';
import translationKeys from '../../../../translations/translationKeys';
import JobSystemLog, {
  JobSystemLogType,
  SystemLogActionType,
  SystemLogActorRole,
} from '../../../../types/JobSystemLog';

const onBehalfLogs = [
  JobSystemLogType.ApplicationDeclinedBySupplier,
  JobSystemLogType.ApplicationApplied,
  JobSystemLogType.ApplicationAppliedViaStandby,
  JobSystemLogType.ApplicationWithdrawn,
  JobSystemLogType.SupplierFeedbackUpdatedAssignment,
  JobSystemLogType.SupplierFeedbackUpdatedAssignmentDuration,
  JobSystemLogType.DemanderFeedbackUpdatedInterpreter,
  JobSystemLogType.DemanderFeedbackUpdatedShowedUp,
  JobSystemLogType.DemanderFeedbackUpdatedJobCompleted,
];

const getTextForLog = (logItem: JobSystemLog, intl: IntlShape) => {
  let name =
    logItem.adminActor?.name ||
    logItem.actor?.name ||
    intl.formatMessage({ id: translationKeys.job_system_log_unknown });
  let onBehalfName = '';

  let textKey = intl.formatMessage(
    { id: translationKeys.job_system_log_default_message },
    { log: logItem.logType },
  );

  let updatedElementKey = `${logItem.logType}`;

  if (logItem.loggedActionType === SystemLogActionType.notification) {
    textKey = intl.formatMessage(
      { id: translationKeys.job_system_log_default_notification_message },
      { log: logItem.logType },
    );
    name =
      logItem.recipient?.name || intl.formatMessage({ id: translationKeys.job_system_log_unknown });
  }

  let key = `job_system_log_${logItem.logType}`;

  if (logItem.logType.includes('job_updated_')) {
    key = `job_system_log_job_updated_sentence`;
  }

  if (
    onBehalfLogs.includes(logItem.logType) &&
    logItem.actorRole === SystemLogActorRole.Admin &&
    logItem.adminActor?.name &&
    logItem.actor?.name
  ) {
    onBehalfName = logItem.actor?.name;
  }

  if (translationKeys[`job_system_log_${logItem.logType}` as keyof typeof translationKeys]) {
    updatedElementKey = `job_system_log_${logItem.logType}`;
  }

  if (Object.keys(translationKeys).includes(key)) {
    textKey = translationKeys[key as keyof typeof translationKeys];
  }
  if (logItem.actorRole === SystemLogActorRole.System) {
    name = intl.formatMessage({ id: translationKeys.job_system_log_system_label });
  }

  return intl.formatMessage(
    {
      id: textKey,
    },
    {
      name: <strong>{name}</strong>,
      updated_element: intl.formatMessage({ id: updatedElementKey }),
      recipient: <strong>{logItem.recipient?.name}</strong>,
      adminName: <strong>{logItem.adminActor?.name}</strong>,
      on_behalf: onBehalfName.length ? (
        <strong>
          {intl.formatMessage(
            { id: translationKeys.job_system_log_on_behalf },
            { on_behalf_name: onBehalfName },
          )}
        </strong>
      ) : undefined,
    },
  );
};

export default getTextForLog;
