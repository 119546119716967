import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Drawer, TextField, Tooltip } from '@skiwo/components';
import { Form, Formik } from 'formik';
import { useGetCustomersQuery } from '../../../Api/Endpoints/Customers/Customers.hooks';
import { useGetJobQuery, useUpdateJobMutation } from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import CcExpandableToggle from '../../../components/CcExpandableToggle/CcExpandableToggle';
import CustomerSearchDropdown, {
  CustomerSearchDropdownMenuOption,
} from '../../../components/CustomerSearchDropdown/CustomerSearchDropdown';
import { customerSchema } from '../../../CreateInterpretationOrder/schema';
import { getCreateInterpretationFormInitialValues } from '../../../CreateInterpretationOrder/utils';
import { appendNestedFormObjectWithEmptyValues } from '../../../helpers/appendNestedFormDataObject';
import { getTypesafeSetFieldValue } from '../../../helpers/getTypesafeSetFieldValue';
import mapManagerCustomerToOption from '../../../helpers/mapManagerCustomerToOption';
import useDebounce from '../../../hooks/useDebounce';
import usePagination from '../../../hooks/usePagination';
import { useLanguages } from '../../../providers/LanguagesProvider';
import { useToast } from '../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../translations/translationKeys';
import useGetJobIdFromParam from '../../hooks/useGetJobIdFromParam';
import {
  JobDetailsDrawerName,
  useJobDetailsDrawer,
} from '../JobDetailsDrawerContext/JobDetailsDrawerContext';
import styles from './EditCustomerDrawer.module.scss';

function getUniqueCustomers(customers: CustomerSearchDropdownMenuOption[]) {
  const uniqueUids = new Set();
  return customers.filter(({ uid }) => {
    if (uniqueUids.has(uid)) return false;
    uniqueUids.add(uid);
    return true;
  });
}

export default function EditCustomerDrawer() {
  const jobId = useGetJobIdFromParam();
  const intl = useIntl();
  const { data: job } = useGetJobQuery(jobId);
  const { languages } = useLanguages();
  const { pagination, setPagination } = usePagination();
  const debounce = useDebounce(300);
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [customerOptions, setCustomerOptions] = useState<CustomerSearchDropdownMenuOption[]>([]);
  const updateJob = useUpdateJobMutation();
  const { showToast, showErrorToast } = useToast();
  const { isDrawerOpen, closeDrawer } = useJobDetailsDrawer();

  const isDrawerOpened = isDrawerOpen(JobDetailsDrawerName.EditCustomerDrawer);
  const handleCloseDrawer = () => closeDrawer(JobDetailsDrawerName.EditCustomerDrawer);

  const { data, isLoading: isGetCustomersLoading } = useGetCustomersQuery(
    {
      's[enterprise_members]': true,
      's[enterprise_cont]': job?.enterprise?.name,
      's[lookup_fields_or_enterprise_or_departments_cont]': debouncedQuery,
      page: pagination.page > 0 ? pagination.page : 1,
    },
    { enabled: !!job },
  );

  useEffect(() => {
    if (!data) return;

    const appendData = data.page > 1;
    const customerOptions =
      data.customers
        .filter((item) => item.uid !== job?.owner?.person?.uid)
        .map((customer) => mapManagerCustomerToOption(customer, intl)) || [];

    setCustomerOptions(appendData ? (prev) => [...prev, ...customerOptions] : customerOptions);
    setPagination({ page: data.page, totalPages: data.pages });
  }, [data]);

  if (!job) return null;

  const person = job.owner?.person;

  const { ccActive, ccEmails, confirmationPhones, bookerName, additionalCustomerUids } =
    getCreateInterpretationFormInitialValues(languages, job);

  const initialCustomerOptions =
    job.attendees?.map((attendee) => mapManagerCustomerToOption(attendee, intl)) ?? [];

  return (
    <Drawer
      title={intl.formatMessage({
        id: translationKeys.job_edit_customer_drawer_title,
      })}
      show={isDrawerOpened}
      onClose={handleCloseDrawer}
    >
      <Formik
        onSubmit={(values, formikProps) => {
          const formData = new FormData();
          const additionalCustomerUids = values.additionalCustomerUids?.length
            ? values.additionalCustomerUids
            : [job.owner?.person?.uid];

          const data = {
            info: {
              realPersonName: values.bookerName,
              ccEmails: values.ccActive ? values.ccEmails : [],
              confirmationPhones: values.ccActive ? values.confirmationPhones : [],
            },
            participants: additionalCustomerUids.filter(Boolean).map((customerUid) => ({
              uid: customerUid,
            })),
          };

          appendNestedFormObjectWithEmptyValues(formData, data);

          updateJob.mutate(
            { id: job.id, body: formData },
            {
              onSuccess: () => {
                formikProps.resetForm();
                handleCloseDrawer();
                showToast({
                  variant: 'success',
                  message: intl.formatMessage({
                    id: translationKeys.job_edit_information_updated_successfully,
                  }),
                });
              },
              onError: (error) => {
                showErrorToast(error);
              },
            },
          );
        }}
        validationSchema={customerSchema({ intl, customerIsShared: person?.isShared })}
        initialValues={{
          ccActive,
          ccEmails,
          confirmationPhones,
          bookerName,
          additionalCustomerUids,
        }}
      >
        {(formikProps) => {
          const setFieldValue = getTypesafeSetFieldValue(formikProps);

          return (
            <Form onSubmit={formikProps.handleSubmit}>
              <div className={styles.body}>
                <div className={styles.card}>
                  {person?.isShared ? (
                    <TextField
                      name="bookerName"
                      size="large"
                      data-testid="customer-booker-name"
                      placeholder={intl.formatMessage({
                        id: translationKeys.job_edit_customer_drawer_booker_name_placeholder,
                      })}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.bookerName}
                      errorText={
                        formikProps.touched.bookerName ? formikProps.errors.bookerName : undefined
                      }
                    />
                  ) : (
                    <RowItem
                      title={intl.formatMessage({
                        id: translationKeys.job_edit_customer_drawer_booker_name_placeholder,
                      })}
                      body={person?.name}
                      node={
                        job?.owner?.person?.avatar ? (
                          <Avatar
                            size="medium"
                            altText={job.owner.person.name}
                            url={job.owner.person.avatar}
                          />
                        ) : null
                      }
                    />
                  )}
                  <RowItem
                    title={intl.formatMessage({
                      id: translationKeys.job_edit_customer_drawer_email_label,
                    })}
                    body={person?.email}
                    node={
                      <Tooltip title={person?.email} copyable>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </Tooltip>
                    }
                  />
                  <RowItem
                    title={intl.formatMessage({
                      id: translationKeys.job_edit_customer_drawer_phone_label,
                    })}
                    body={person?.phone}
                    node={
                      <Tooltip title={person?.phone ?? ''} copyable>
                        <FontAwesomeIcon icon={faPhone} />
                      </Tooltip>
                    }
                  />

                  <hr className={styles.hr} />

                  <RowItem
                    title={intl.formatMessage({
                      id: translationKeys.job_edit_customer_drawer_enterprise_label,
                    })}
                    body={job?.enterprise?.name}
                    node={
                      job?.enterprise?.logoUrl ? (
                        <Avatar
                          size="medium"
                          altText={job.enterprise.name}
                          url={job.enterprise.logoUrl}
                        />
                      ) : null
                    }
                  />
                  <RowItem
                    title={intl.formatMessage({
                      id: translationKeys.job_edit_customer_drawer_department_label,
                    })}
                    body={job?.department?.name}
                  />
                  <RowItem
                    title={intl.formatMessage({
                      id: translationKeys.job_edit_customer_drawer_department_email_label,
                    })}
                    body={job?.department?.email}
                    node={
                      <Tooltip title={job?.department?.email ?? ''} copyable>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </Tooltip>
                    }
                  />
                  <RowItem
                    title={intl.formatMessage({
                      id: translationKeys.job_edit_customer_drawer_department_phone_label,
                    })}
                    body={job?.department?.phoneNumber}
                    node={
                      <Tooltip title={job?.department?.phoneNumber ?? ''} copyable>
                        <FontAwesomeIcon icon={faPhone} />
                      </Tooltip>
                    }
                  />

                  <hr className={styles.hr} />

                  <CustomerSearchDropdown
                    options={customerOptions}
                    placeholder={intl.formatMessage({
                      id: translationKeys.job_edit_customer_drawer_attendees_placeholder,
                    })}
                    selected={
                      formikProps.values.additionalCustomerUids
                        ? getUniqueCustomers(
                            [...customerOptions, ...initialCustomerOptions].filter(({ uid }) =>
                              formikProps.values.additionalCustomerUids?.includes(uid),
                            ),
                          )
                        : []
                    }
                    pagination={pagination}
                    isLoading={isGetCustomersLoading}
                    onSearch={(value) => {
                      debounce(() => {
                        setDebouncedQuery(value);
                      });
                    }}
                    onLoadMore={() => {
                      setPagination({ ...pagination, page: pagination.page + 1 });
                    }}
                    onChange={(customers) => {
                      const customerUids = customers?.map((item) => item.uid) ?? [];

                      formikProps.setFieldValue('additionalCustomerUids', customerUids);
                    }}
                    multiple
                  />
                </div>
                <CcExpandableToggle
                  active={formikProps.values.ccActive}
                  emails={formikProps.values.ccEmails}
                  confirmationPhones={formikProps.values.confirmationPhones}
                  onActiveChange={(checked) => {
                    setFieldValue('ccActive', checked);
                    setFieldValue('ccEmails', []);
                    setFieldValue('confirmationPhones', []);
                  }}
                  onAddEmail={(email) => {
                    setFieldValue('ccEmails', [...formikProps.values.ccEmails, email]);
                  }}
                  onAddPhone={(phone) => {
                    setFieldValue('confirmationPhones', [
                      ...formikProps.values.confirmationPhones,
                      phone,
                    ]);
                  }}
                  onDeleteEmail={(email) => {
                    setFieldValue(
                      'ccEmails',
                      formikProps.values.ccEmails.filter((e) => e !== email),
                    );
                  }}
                  onDeletePhone={(phone) => {
                    setFieldValue(
                      'confirmationPhones',
                      formikProps.values.confirmationPhones.filter((p) => p !== phone),
                    );
                  }}
                  isErrorVisible={
                    (formikProps.touched.ccActive || formikProps.touched.ccEmails) &&
                    !!formikProps.errors.ccEmails
                  }
                />
              </div>
              <div className={styles.formFooter}>
                <Button onClick={handleCloseDrawer} type="button" size="x-large" variant="gray">
                  {intl.formatMessage({
                    id: translationKeys.job_edit_customer_drawer_cancel_label,
                  })}
                </Button>
                <Button type="submit" isLoading={updateJob.isPending} size="x-large">
                  {intl.formatMessage({
                    id: translationKeys.job_edit_customer_drawer_save_label,
                  })}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Drawer>
  );
}

const RowItem = ({
  title,
  body,
  node,
}: {
  title: string;
  body?: string | null;
  node?: React.ReactNode;
}) => {
  if (!body) return null;

  return (
    <div className={styles.rowItem}>
      <div>
        <div className={styles.rowItemTitle}>{title}</div>
        <div className={styles.rowItemBody}>{body}</div>
      </div>
      {node}
    </div>
  );
};
