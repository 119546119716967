import React, { useEffect, useState } from 'react';
import { faCircleCheck, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { CustomerPaymentMethod } from '../../types';

export default function usePaymentRegexHint({
  selectedPaymentMethod,
  defaultBookingReference,
  defaultPaymentBookingReference,
}: {
  selectedPaymentMethod?: CustomerPaymentMethod;
  defaultBookingReference?: string;
  defaultPaymentBookingReference?: string;
}) {
  const [regexHintIcon, setRegexHintIcon] = useState({
    bookingRefIcon: faInfoCircle,
    paymentBookingRefIcon: faInfoCircle,
  });

  const matchRegex = (value: string, fieldName: string) => {
    if (!selectedPaymentMethod) return;
    const pattern =
      fieldName === 'bookingReference'
        ? selectedPaymentMethod.bookingReferenceRegex
        : selectedPaymentMethod.paymentBookingReferenceRegex;

    if (!pattern) return;

    const regex = new RegExp(pattern);
    const isValidValue = regex.test(value);

    const iconName = fieldName === 'bookingReference' ? 'bookingRefIcon' : 'paymentBookingRefIcon';
    const iconValue = isValidValue ? faCircleCheck : faInfoCircle;

    setRegexHintIcon((prevIcons) => ({
      ...prevIcons,
      [iconName]: iconValue,
    }));
  };

  useEffect(() => {
    if (defaultBookingReference) matchRegex(defaultBookingReference, 'bookingReference');
    if (defaultPaymentBookingReference)
      matchRegex(defaultPaymentBookingReference, 'paymentBookingReference');
  }, [selectedPaymentMethod]);

  const matchRegexOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field?: string,
  ) => {
    if (!selectedPaymentMethod) return;

    matchRegex(e.target.value, e.target.name ?? field);
  };

  return { regexHintIcon, matchRegex: matchRegexOnChange };
}
