import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag } from '@skiwo/components';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerJobDiscussionStatus } from '../../../../types/ManagerJob';

interface DiscussionStatusTagProps {
  status: ManagerJobDiscussionStatus;
}

export const DiscussionStatusTag = ({ status }: DiscussionStatusTagProps) => {
  switch (status) {
    case ManagerJobDiscussionStatus.Applied:
      return (
        <Tag color="warning" size="small" variant="square">
          <FormattedMessage id={translationKeys.job_discussion_status_applied} />
        </Tag>
      );
    case ManagerJobDiscussionStatus.Confirmed:
      return (
        <Tag color="success" size="small" variant="square">
          <FormattedMessage id={translationKeys.job_discussion_status_confirmed} />
        </Tag>
      );
    case ManagerJobDiscussionStatus.Rejected:
      return (
        <Tag color="neutral" size="small" variant="square">
          <FormattedMessage id={translationKeys.job_discussion_status_rejected} />
        </Tag>
      );
    case ManagerJobDiscussionStatus.Declined:
      return (
        <Tag color="neutral" size="small" variant="square">
          <FormattedMessage id={translationKeys.job_discussion_status_declined} />
        </Tag>
      );
    case ManagerJobDiscussionStatus.Invited:
      return (
        <Tag color="warning" size="small" variant="square">
          <FormattedMessage id={translationKeys.job_discussion_status_invited} />
        </Tag>
      );
    case ManagerJobDiscussionStatus.Withdrawn:
      return (
        <Tag color="error" size="small" variant="square">
          <FormattedMessage id={translationKeys.job_discussion_status_withdrawn} />
        </Tag>
      );

    default:
      return <></>;
  }
};
