import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Modal } from '@skiwo/components';
import { useFinishJobMutation } from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import { useToast } from '../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../translations/translationKeys';
import useGetJobIdFromParam from '../../hooks/useGetJobIdFromParam';
import styles from './FinishJobModal.module.scss';

interface FinishJobModalProps {
  show: boolean;
  onClose: () => void;
}

export default function FinishJobModal({ show, onClose }: FinishJobModalProps) {
  const intl = useIntl();
  const jobId = useGetJobIdFromParam();
  const finishJob = useFinishJobMutation();
  const { showErrorToast, showToast } = useToast();

  if (!jobId) return null;

  return (
    <Modal
      title={intl.formatMessage({ id: translationKeys.job_finish_modal_title })}
      show={show}
      onHide={onClose}
      size="large"
    >
      <div className={styles.modalBody}>
        {intl.formatMessage({ id: translationKeys.job_finish_modal_content })}
        <div className={styles.buttonWrapper}>
          <Button variant="white" size="large" onClick={onClose}>
            {intl.formatMessage({ id: translationKeys.job_finish_modal_cancel_button_label })}
          </Button>
          <Button
            variant="primary"
            size="large"
            onClick={() => {
              finishJob.mutate(jobId, {
                onSuccess: () => {
                  showToast({
                    message: intl.formatMessage({
                      id: translationKeys.job_finish_modal_success_message,
                    }),
                    variant: 'success',
                  });
                  onClose();
                },
                onError: (error) => {
                  showErrorToast(error);
                },
              });
            }}
            isLoading={finishJob.isPending}
          >
            {intl.formatMessage({ id: translationKeys.job_finish_modal_finish_button_label })}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
