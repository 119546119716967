import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, FileState, Modal, SearchDropdownMenuOption } from '@skiwo/components';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useCreateJobMutation, useGetJobQuery } from '../Api/Endpoints/Jobs/Jobs.hooks';
import { useCheckForDuplicateJobIdsMutation } from '../Api/Endpoints/Jobs/Jobs.hooks';
import { useGetFilesQueries } from '../Api/Endpoints/System/System.hooks';
import { CustomerSearchDropdownMenuOption } from '../components/CustomerSearchDropdown/CustomerSearchDropdown';
import SearchCustomerSection, {
  CustomerShape,
} from '../components/SearchCustomerSection/SearchCustomerSection';
import { appendNestedFormDataObjectWithoutEmptyValues } from '../helpers/appendNestedFormDataObject';
import { getDefaultReferences } from '../helpers/getDefaultReferences';
import routes from '../helpers/routes';
import { useScrollToFormikError } from '../hooks/useScrollToFormikError';
import { useLanguages } from '../providers/LanguagesProvider';
import { useQualifications } from '../providers/QualificationsProvider';
import { useToast } from '../providers/ToastProvider/ToastProvider';
import translationKeys from '../translations/translationKeys';
import { CustomerPaymentMethod, EnterpriseDepartment } from '../types';
import { ManagerJobDetails } from '../types/ManagerJob';
import CustomerSection from './CustomerSection/CustomerSection';
import { InformationSection } from './InformationSection/InformationSection';
import { ManualBookingSection } from './ManualBookingSection/ManualBookingSection';
import { PaymentSection } from './PaymentSection/PaymentSection';
import SettingsSection from './SettingsSection/SettingsSection';
import { SummaryView } from './SummaryView/SummaryView';
import getCreateInterpretationOrderFormData, {
  getCreateInterpretationOrderData,
  GetCreateInterpretationOrderFormDataProps,
} from './utils/getCreateInterpretationOrderFormData';
import { CreateInterpretationOrderFormValues, createInterpretationOrderSchema } from './schema';
import { getCreateInterpretationFormInitialValues } from './utils';
import styles from './CreateInterpretationOrder.module.scss';

const getCustomerShapeFromJob = (job?: ManagerJobDetails | null): CustomerShape | undefined => {
  if (job?.owner?.person) {
    return {
      ...job.owner.person,
      defaultBookingReference: job.owner.defaultBookingReference ?? undefined,
      defaultPaymentBookingReference: job.owner.defaultPaymentBookingReference ?? undefined,
      enterprise: job.enterprise ?? undefined,
    };
  }

  return undefined;
};

const CreateInterpretationOrder = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { getDefaultLanguage, languages } = useLanguages();
  const { showErrorToast } = useToast();
  const { qualifications } = useQualifications();
  const [confirmationPage, setConfirmationPage] = useState(false);
  const [customer, setCustomer] = useState<CustomerShape>();
  const [selectedDepartment, setSelectedDepartment] = useState<SearchDropdownMenuOption>();
  const [additionalCustomers, setAdditionalCustomers] = useState<
    CustomerSearchDropdownMenuOption[]
  >([]);
  const [departmentList, setDepartmentList] = useState<EnterpriseDepartment[]>();
  const [paymentMethod, setPaymentMethod] = useState<CustomerPaymentMethod>();
  const [isCategoryRequired, setIsCategoryRequired] = useState<boolean>(false);
  const [isCategorySubjectRequired, setIsCategorySubjectRequired] = useState<boolean>(false);
  const createJob = useCreateJobMutation();
  const [params, setParams] = useSearchParams();
  const jobId = params.get('jobid');
  const godmode = params.get('godmode');
  const defaultLanguageId = getDefaultLanguage()?.id;
  const [duplicatedJobIds, setDuplicatedJobIds] = useState<string[]>([]);
  const checkForDuplicateJobIds = useCheckForDuplicateJobIdsMutation();
  const { data: job } = useGetJobQuery(jobId ?? undefined);
  const queries = useGetFilesQueries(job?.attachments ?? []);

  const initialAttachments = queries
    .filter((query) => !!query.data)
    .map((query, i) => ({
      ...query.data,
      id: i,
    })) as FileState[];

  const customerShape = getCustomerShapeFromJob(job) ?? customer;

  const schema = createInterpretationOrderSchema({
    customerIsShared: customerShape?.isShared,
    intl,
    paymentMethod,
    isCategoryRequired,
    isCategorySubjectRequired,
  });

  const resetSelections = () => {
    setCustomer(undefined);
    setSelectedDepartment(undefined);
    setDepartmentList(undefined);
    setAdditionalCustomers([]);
    setParams({});
  };

  const handleOnSubmit = async (values: CreateInterpretationOrderFormValues) => {
    if (!customerShape) return;
    if (confirmationPage) {
      const department =
        job?.department ?? departmentList?.find((item) => item.id === selectedDepartment?.id);

      const {
        defaultReference: defaultBookingReference,
        defaultReferenceFrom: defaultBookingReferenceFrom,
      } = getDefaultReferences({
        allowReference: paymentMethod?.allowBookingReference,
        customerDefaultReference: customerShape.defaultBookingReference,
        departmentDefaultReference: department?.defaultBookingReference,
        enterpriseDefaultReference: customerShape.enterprise?.defaultBookingReference,
      });

      const {
        defaultReference: defaultPaymentBookingReference,
        defaultReferenceFrom: defaultPaymentBookingReferenceFrom,
      } = getDefaultReferences({
        allowReference: paymentMethod?.allowPaymentBookingReference,
        customerDefaultReference: customerShape.defaultPaymentBookingReference,
        departmentDefaultReference: department?.defaultPaymentBookingReference,
        enterpriseDefaultReference: customerShape.enterprise?.defaultPaymentBookingReference,
      });

      const interpretationOrderFormDataProps: GetCreateInterpretationOrderFormDataProps = {
        originalJobId: jobId,
        defaultBookingReference,
        defaultBookingReferenceFrom,
        defaultPaymentBookingReference,
        defaultPaymentBookingReferenceFrom,
        qualifications,
        defaultLanguageId,
        values,
        customerUid: customerShape.uid,
        forceCreate: duplicatedJobIds.length > 0,
      };

      const formData = getCreateInterpretationOrderFormData(interpretationOrderFormDataProps);

      createJob.mutate(formData, {
        onSuccess: (data) => {
          if (data && data.data) {
            const godmodeUrl = data.data.godmodeDemanderUrl;
            const newJobId = data.data.id;
            if ((godmode && jobId) || jobId === null) {
              window.location.href = godmodeUrl;
            } else {
              navigate(`${routes.jobs}/${newJobId}`);
            }
          }
          setDuplicatedJobIds([]);
        },
        onError: async (error) => {
          showErrorToast(error);

          const duplicatedJobsErrorSchema = yup.object({
            jobExists: yup.string().required(),
          });

          const isDuplicatedJobError = await duplicatedJobsErrorSchema.isValid(error?.text);

          if (isDuplicatedJobError) {
            const checkForDuplicateJobIdsFormData = new FormData();

            const orderData = getCreateInterpretationOrderData({
              ...interpretationOrderFormDataProps,
              originalJobId: jobId,
            });

            appendNestedFormDataObjectWithoutEmptyValues(checkForDuplicateJobIdsFormData, {
              ownerUid: orderData.ownerUid,
              interpretationRequirement: orderData.interpretationRequirement,
            });

            checkForDuplicateJobIds.mutate(checkForDuplicateJobIdsFormData, {
              onSuccess: ({ data }) => {
                if (data?.ids) {
                  setDuplicatedJobIds(data.ids.map((id) => `#${id}`));
                }
              },
            });
          }
        },
      });
    } else {
      setConfirmationPage(true);
    }
  };

  return (
    <Container fluid>
      <Row className={styles.row}>
        <Col lg={6} data-testid="interpretation-booking-form">
          <div className={styles.header}>
            <span>
              {!confirmationPage ? (
                <FormattedMessage id={translationKeys.create_interpretation_order_label} />
              ) : (
                <FormattedMessage id={translationKeys.create_interpretation_order_summary_label} />
              )}
            </span>
          </div>
          <Formik<CreateInterpretationOrderFormValues>
            key={initialAttachments.length || customerShape?.uid}
            validationSchema={schema}
            initialValues={getCreateInterpretationFormInitialValues(
              languages,
              job,
              initialAttachments,
              customerShape,
            )}
            onSubmit={handleOnSubmit}
          >
            {(formikProps) => {
              useScrollToFormikError(formikProps.isSubmitting);
              return (
                <Form onSubmit={formikProps.handleSubmit} className={styles.column}>
                  {!confirmationPage && !customerShape && (
                    <SearchCustomerSection setCustomer={setCustomer} formikProps={formikProps} />
                  )}

                  {!confirmationPage && !!customerShape && (
                    <>
                      <CustomerSection
                        customer={customerShape}
                        changeCustomer={() => resetSelections()}
                        formikProps={formikProps}
                        selectedDepartment={selectedDepartment}
                        setSelectedDepartment={setSelectedDepartment}
                        setDepartmentList={setDepartmentList}
                        additionalCustomers={additionalCustomers}
                        setAdditionalCustomers={setAdditionalCustomers}
                      />

                      <PaymentSection
                        customer={customerShape}
                        selectedPaymentMethod={paymentMethod}
                        setSelectedPaymentMethod={setPaymentMethod}
                        selectedDepartment={selectedDepartment}
                        departmentList={departmentList}
                        formikProps={formikProps}
                        jobDepartment={job?.department}
                      />

                      <InformationSection
                        formikProps={formikProps}
                        enterpriseId={customer?.enterprise?.id ?? job?.enterprise?.id}
                        setIsCategoryRequired={setIsCategoryRequired}
                        setIsCategorySubjectRequired={setIsCategorySubjectRequired}
                      />

                      <SettingsSection
                        formikProps={formikProps}
                        customer={{
                          emailVerified: customerShape?.emailVerified ?? false,
                        }}
                      />

                      <ManualBookingSection formikProps={formikProps} />

                      <div className={styles.buttonsWrapper}>
                        <Button type="submit" size="x-large">
                          <FormattedMessage
                            id={translationKeys.create_interpretation_order_preview_booking_button}
                          />
                        </Button>
                      </div>
                    </>
                  )}

                  {confirmationPage && !!customerShape && (
                    <>
                      <SummaryView
                        customer={customerShape}
                        selectedDepartment={selectedDepartment?.label}
                        additionalCustomers={additionalCustomers}
                        paymentMethod={paymentMethod}
                        formikProps={formikProps}
                      />
                      <div className={styles.buttonsWrapper}>
                        <Button
                          variant="gray"
                          size="x-large"
                          onClick={() => setConfirmationPage(false)}
                        >
                          <FormattedMessage
                            id={translationKeys.create_translation_order_summary_back_button}
                          />
                        </Button>
                        <Button
                          type="submit"
                          size="x-large"
                          isLoading={createJob.isPending || checkForDuplicateJobIds.isPending}
                        >
                          <FormattedMessage
                            id={translationKeys.create_translation_order_summary_place_order_button}
                          />
                        </Button>
                      </div>
                      <Modal
                        show={duplicatedJobIds.length > 0}
                        title={intl.formatMessage({
                          id: translationKeys.create_interpretation_order_duplicated_jobs_modal_title,
                        })}
                        onHide={() => setDuplicatedJobIds([])}
                        onCancel={() => setDuplicatedJobIds([])}
                        submitButtonText={intl.formatMessage({
                          id: translationKeys.create_interpretation_order_duplicated_jobs_modal_submit_label,
                        })}
                        onSubmit={formikProps.handleSubmit}
                        isSubmitting={createJob.isPending}
                      >
                        {duplicatedJobIds.length > 1
                          ? intl.formatMessage(
                              {
                                id: translationKeys.create_interpretation_order_multiple_duplicated_jobs_modal_message,
                              },
                              {
                                ids: duplicatedJobIds.join(', '),
                              },
                            )
                          : intl.formatMessage(
                              {
                                id: translationKeys.create_interpretation_order_single_duplicated_job_modal_message,
                              },
                              {
                                id: duplicatedJobIds[0],
                              },
                            )}
                      </Modal>
                    </>
                  )}
                </Form>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateInterpretationOrder;
