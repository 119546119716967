import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Button, FileState, SearchDropdownMenuOption } from '@skiwo/components';
import { Form, Formik } from 'formik';
import SearchCustomerSection, {
  CustomerShape,
} from '../components/SearchCustomerSection/SearchCustomerSection';
import routes from '../helpers/routes';
import { useScrollToFormikError } from '../hooks/useScrollToFormikError';
import { useApi } from '../providers/ApiProvider';
import { useToast } from '../providers/ToastProvider/ToastProvider';
import translationKeys from '../translations/translationKeys';
import {
  CustomerPaymentMethod,
  EnterpriseDepartment,
  ManagerOrderAccessLevel,
  ManagerOrderDeliveryMethod,
  ManagerOrderSubtaskType,
} from '../types';
import { CreateManagerOrder } from '../types/ManagerOrder';
import CustomerSection from './CustomerSection/CustomerSection';
import {
  alternativeLanguageNoPreference,
  InformationSection,
} from './InformationSection/InformationSection';
import { PaymentSection } from './PaymentSection/PaymentSection';
import { PostageSection, PostageType } from './PostageSection/PostageSection';
import SettingsSection from './SettingsSection/SettingsSection';
import { SummaryView } from './SummaryView/SummaryView';
import { createTranslationOrderErrorKeys } from './utils/createTranslationOrderErrorKeys';
import createTranslationOrderSchema from './schema';
import styles from './CreateTranslationOrder.module.scss';

export interface CreateTranslationOrderFormValues {
  customerId: string;
  bookerName?: string;
  departmentId: string;
  paymentMethodId: string;
  bookingReference: string;
  paymentBookingReference: string;
  caseNumber: string;
  clientName: string;
  clientId: string;
  deliveryMethod: ManagerOrderDeliveryMethod;
  sendByPostAddressIncludedInCoverLetter: boolean;
  sendByPostAddress: string;
  sendByPostEnterpriseName: string;
  sendByPostName: string;
  coverLetters: FileState[];
  bookedSubtaskType: ManagerOrderSubtaskType;
  sourceLanguageId: string;
  targetLanguageIds: string[];
  alternativeTargetLanguageId: string;
  attachments: FileState[];
  filePasswords: Record<string, string>;
  countOfWords: string;
  externalDeadline: string | null;
  translationMethodId?: string | null;
  otherSubject: string;
  instructionsFromDemander: string;
  ccEmails: string[];
  accessLevel?: ManagerOrderAccessLevel;
}

const CreateTranslationOrder = () => {
  const intl = useIntl();
  const api = useApi();
  const navigate = useNavigate();
  const { showErrorToast } = useToast();
  const [customer, setCustomer] = useState<CustomerShape>();
  const [selectedDepartment, setSelectedDepartment] = useState<SearchDropdownMenuOption>();
  const [departmentList, setDepartmentList] = useState<EnterpriseDepartment[]>();
  const [paymentMethod, setPaymentMethod] = useState<CustomerPaymentMethod>();
  const [coverLetterFiles, setCoverLetterFiles] = useState<FileState[]>([]);
  const [postageAddressRequired, setPostageAddressRequired] = useState(false);
  const [postageType, setPostageType] = useState<PostageType>(PostageType.CoverLetter);
  const [noSourceLanguage, setNoSourceLanguage] = useState(false);
  const [passwordProtected, setPasswordProtected] = useState(false);
  const [sourceFiles, setSourceFiles] = useState<FileState[]>([]);
  const [confirmationPage, setConfirmationPage] = useState(false);
  const [selectedTranslationMethodOption, setSelectedTranslationMethodOption] =
    useState<SearchDropdownMenuOption>();
  const [ccEmailsActive, setCcEmailsActive] = useState(false);
  const [accessLevelActive, setAccessLevelActive] = useState(false);
  const [createOrderLoading, setCreateOrderLoading] = useState(false);

  const schema = createTranslationOrderSchema({
    isShared: customer?.isShared,
    intl,
    paymentMethod,
    postageType,
    postageAddressRequired,
    noSourceLanguage,
  });

  const getAttachmentsBlob = (formData: FormData, filePasswords: Record<string, string>) => {
    sourceFiles.forEach((file, index) => {
      const fileBlob = new Blob([file.data], {
        type: file.type,
      });

      formData.append(`attachments[${index}][file]`, fileBlob, file.name);

      if (passwordProtected && filePasswords[file.uid]) {
        formData.append(`attachments[${index}][filePassword]`, filePasswords[file.uid]);
        formData.append(`attachments[${index}][protectWithPassword]`, 'true');
      }
    });
  };

  const getCoverLetterBlob = (formData: FormData) => {
    coverLetterFiles.forEach((file, index) => {
      const fileBlob = new Blob([file.data], {
        type: file.type,
      });

      formData.append(`coverLetters[${index}][file]`, fileBlob, file.name);
    });
  };

  const getFormArray = (key: string, data: any[], formData: FormData) => {
    data.forEach((item, index) => {
      formData.append(`${key}[${index}]`, item);
    });
  };

  const resetSelections = () => {
    setCustomer(undefined);
    setSelectedDepartment(undefined);
    setDepartmentList(undefined);
    setPaymentMethod(undefined);
    setCoverLetterFiles([]);
    setPostageAddressRequired(false);
    setPostageType(PostageType.CoverLetter);
    setNoSourceLanguage(false);
    setPasswordProtected(false);
    setSourceFiles([]);
    setConfirmationPage(false);
    setSelectedTranslationMethodOption(undefined);
    setCcEmailsActive(false);
    setAccessLevelActive(false);
    setCreateOrderLoading(false);
  };

  const handleOnSubmit = async (values: CreateTranslationOrderFormValues) => {
    if (!customer) return;
    if (confirmationPage) {
      const createOrderData: CreateManagerOrder = {
        ownerUid: customer.uid,
        departmentId: selectedDepartment?.id.toString(),
        realOwnerName: values.bookerName,
        paymentMethodId: values.paymentMethodId,
        bookingReference: values.bookingReference,
        paymentBookingReference: values.paymentBookingReference,
        caseNumber: values.caseNumber,
        clientName: values.clientName,
        clientId: values.clientId,
        deliveryMethod: values.deliveryMethod,
        sendByPostAddressIncludedInCoverLetter:
          values.deliveryMethod === ManagerOrderDeliveryMethod.Post &&
          postageType === PostageType.CoverLetter
            ? values.sendByPostAddressIncludedInCoverLetter
            : undefined,
        sendByPostAddress: values.sendByPostAddress,
        sendByPostEnterpriseName: values.sendByPostEnterpriseName,
        sendByPostName: values.sendByPostName,
        bookedSubtaskType: values.bookedSubtaskType,
        sourceLanguageId: values.sourceLanguageId,
        targetLanguageIds: values.targetLanguageIds,
        alternativeTargetLanguageId:
          values.alternativeTargetLanguageId === alternativeLanguageNoPreference
            ? undefined
            : values.alternativeTargetLanguageId,
        countOfWords: values.countOfWords,
        externalDeadline: values.externalDeadline,
        translationMethodId: values.translationMethodId ? values.translationMethodId : undefined,
        otherSubject: values.otherSubject || undefined,
        ccEmails: values.ccEmails,
        accessLevel: values.accessLevel,
        instructionsFromDemander: values.instructionsFromDemander,
      };
      const createOrderFormData = new FormData();

      Object.keys(createOrderData).forEach((key) => {
        if (
          createOrderData[key as keyof CreateManagerOrder] ||
          createOrderData[key as keyof CreateManagerOrder] === null
        ) {
          if (Array.isArray(createOrderData[key as keyof CreateManagerOrder])) {
            getFormArray(
              key,
              createOrderData[key as keyof CreateManagerOrder],
              createOrderFormData,
            );
          } else {
            createOrderFormData.append(key, createOrderData[key as keyof CreateManagerOrder] || '');
          }
        }
      });

      getAttachmentsBlob(createOrderFormData, values.filePasswords);
      getCoverLetterBlob(createOrderFormData);

      const { data, error } = await api.createTranslationOrder(
        createOrderFormData,
        setCreateOrderLoading,
      );

      if (error) {
        showErrorToast(error, createTranslationOrderErrorKeys);
      } else {
        data && navigate(`${routes.translationSubtasks}/${data.id}`);
      }
    } else {
      setConfirmationPage(true);
    }
  };

  return (
    <Container fluid>
      <Row className={styles.row}>
        <Col lg={6} data-testid="translation-booking-form">
          <div className={styles.header}>
            <span>
              {!confirmationPage ? (
                <FormattedMessage id={translationKeys.create_translation_order_label} />
              ) : (
                <FormattedMessage id={translationKeys.create_translation_order_summary_label} />
              )}
            </span>
          </div>
          <Formik<CreateTranslationOrderFormValues>
            key={customer?.uid}
            validationSchema={schema}
            initialValues={{
              customerId: customer?.id?.toString() ?? '',
              bookerName: '',
              departmentId: '',
              bookingReference: '',
              paymentBookingReference: '',
              caseNumber: '',
              clientName: '',
              clientId: '',
              paymentMethodId: '',
              deliveryMethod: ManagerOrderDeliveryMethod.Email,
              sendByPostAddressIncludedInCoverLetter: true,
              sendByPostAddress: '',
              sendByPostEnterpriseName: '',
              sendByPostName: '',
              coverLetters: [],
              bookedSubtaskType: ManagerOrderSubtaskType.Translation,
              countOfWords: '',
              externalDeadline: null,
              translationMethodId: undefined,
              otherSubject: '',
              instructionsFromDemander: '',
              sourceLanguageId: '',
              targetLanguageIds: [],
              alternativeTargetLanguageId: '',
              attachments: [],
              filePasswords: {},
              ccEmails: [],
              accessLevel: undefined,
            }}
            onSubmit={handleOnSubmit}
          >
            {(formikProps) => {
              useScrollToFormikError(formikProps.isSubmitting);
              return (
                // eslint-disable-next-line react/prop-types
                <Form onSubmit={formikProps.handleSubmit} className={styles.column}>
                  {!customer ? (
                    <SearchCustomerSection setCustomer={setCustomer} formikProps={formikProps} />
                  ) : !confirmationPage ? (
                    <>
                      <CustomerSection
                        customer={customer}
                        changeCustomer={() => resetSelections()}
                        formikProps={formikProps}
                        selectedDepartment={selectedDepartment}
                        setSelectedDepartment={setSelectedDepartment}
                        setDepartmentList={setDepartmentList}
                      />
                      <PaymentSection
                        customer={customer}
                        selectedPaymentMethod={paymentMethod}
                        setSelectedPaymentMethod={setPaymentMethod}
                        selectedDepartment={selectedDepartment}
                        departmentList={departmentList}
                        formikProps={formikProps}
                      />
                      <PostageSection
                        formikProps={formikProps}
                        coverLetterFiles={coverLetterFiles}
                        setCoverLetterFiles={setCoverLetterFiles}
                        postageAddressRequired={postageAddressRequired}
                        setPostageAddressRequired={setPostageAddressRequired}
                        postageType={postageType}
                        setPostageType={setPostageType}
                      />
                      <InformationSection
                        formikProps={formikProps}
                        sourceFiles={sourceFiles}
                        setSourceFiles={setSourceFiles}
                        noSourceLanguage={noSourceLanguage}
                        setNoSourceLanguage={setNoSourceLanguage}
                        passwordProtected={passwordProtected}
                        setPasswordProtected={setPasswordProtected}
                        selectedTranslationMethodOption={selectedTranslationMethodOption}
                        setSelectedTranslationMethodOption={setSelectedTranslationMethodOption}
                      />
                      <SettingsSection
                        formikProps={formikProps}
                        ccEmailsActive={ccEmailsActive}
                        setCcEmailsActive={setCcEmailsActive}
                        accessLevelActive={accessLevelActive}
                        setAccessLevelActive={setAccessLevelActive}
                      />

                      <div className={styles.buttonsWrapper}>
                        <Button type="submit" size="x-large" data-testid="preview-order-button">
                          <FormattedMessage
                            id={translationKeys.create_translation_order_preview_order_button}
                          />
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <SummaryView
                        customer={customer}
                        selectedDepartment={selectedDepartment?.label}
                        paymentMethod={paymentMethod}
                        postageAddressRequired={postageAddressRequired}
                        selectedTranslationMethod={selectedTranslationMethodOption?.label}
                        formikProps={formikProps}
                      />
                      <div className={styles.buttonsWrapper}>
                        <Button
                          variant="gray"
                          size="x-large"
                          onClick={() => setConfirmationPage(false)}
                        >
                          <FormattedMessage
                            id={translationKeys.create_translation_order_summary_back_button}
                          />
                        </Button>
                        <Button type="submit" size="x-large" isLoading={createOrderLoading}>
                          <FormattedMessage
                            id={translationKeys.create_translation_order_summary_place_order_button}
                          />
                        </Button>
                      </div>
                    </>
                  )}
                </Form>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateTranslationOrder;
