import { IntlShape } from 'react-intl';
import translationKeys from '../translations/translationKeys';

interface PaymentMethodData {
  orgName?: string;
  orgNumber?: string;
  email?: string | null;
}

export const getPaymentMethodName = (paymentMethodData: PaymentMethodData, intl: IntlShape) => {
  const { orgName, orgNumber, email } = paymentMethodData;

  return orgNumber
    ? `${orgName || '-'} / ${orgNumber}`
    : `${email || '-'} / ${intl.formatMessage({
        id: translationKeys.translation_order_edit_invoicing_payment_methods_email_label,
      })}`;
};
