import React from 'react';
import { Avatar, Skeleton } from '@skiwo/components';
import styles from './SystemLog.module.scss';

const SystemLogLoader = () => {
  return (
    <>
      {[...Array(3)].map((_, index) => (
        <div key={index} className={styles.logItem}>
          <div className={styles.avatar}>
            <Avatar altText="Loading" size="medium" />
          </div>

          <div className={styles.skeleton}>
            <Skeleton variant="details" />
          </div>
        </div>
      ))}
    </>
  );
};

export default SystemLogLoader;
