import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { ManagerJobSessionType } from '../../types';

export const getSessionTypeLabel = (sessionType: ManagerJobSessionType, intl: IntlShape) => {
  switch (sessionType) {
    case ManagerJobSessionType.Phone:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_assignment_type_phone_label,
      });
    case ManagerJobSessionType.Video:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_assignment_type_video_label,
      });
    case ManagerJobSessionType.InPerson:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_assignment_type_in_person_label,
      });
    default:
      return '';
  }
};
