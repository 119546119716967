import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tabs } from '@skiwo/components';
import { stripObject } from '@skiwo/utils';
import { ApiError } from '../Api';
import CreateSupplierDrawer from '../Drawers/CreateSupplierDrawer/CreateSupplierDrawer';
import { useApi } from '../providers/ApiProvider';
import translationKeys from '../translations/translationKeys';
import { ManagerSupplier, ManagerSupplierAccountStatus, ManagerSuppliersResponse } from '../types';
import SuppliersTable from './SuppliersTable/SuppliersTable';
import getStatusName from './utils/getStatusName';
import getSuppliersFilters from './utils/getSuppliersFilters';
import SuppliersFilterField from './SuppliersFilterField';
import SupplierTabs from './SupplierTab';
import styles from './Suppliers.module.scss';

const Suppliers = () => {
  const [suppliers, setSuppliers] = useState<ManagerSupplier[]>([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState('');
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [suppliersLoading, setSuppliersLoading] = useState(false);
  const [suppliersError, setSuppliersError] = useState<ApiError | null>(null);
  const [suppliersStats, setSuppliersStats] = useState<ManagerSuppliersResponse['statistics']>();
  const [filters, setFilters] = useState<Record<string, string>>({});
  const api = useApi();
  const tabItems = [
    {
      count: suppliersStats?.active,
      id: SupplierTabs.Active,
      title: getStatusName(ManagerSupplierAccountStatus.Active, intl),
      filters: {
        's[current_account_status_name_eq]': 0,
      },
    },
    {
      count: suppliersStats?.paused,
      id: SupplierTabs.Paused,
      title: getStatusName(ManagerSupplierAccountStatus.Paused, intl),
      filters: {
        's[current_account_status_name_eq]': 1,
      },
    },
    {
      count: suppliersStats?.blocked,
      id: SupplierTabs.Blocked,
      title: getStatusName(ManagerSupplierAccountStatus.Blocked, intl),
      filters: {
        's[current_account_status_name_eq]': 2,
      },
    },
    {
      count: suppliersStats?.retired,
      id: SupplierTabs.Retired,
      title: getStatusName(ManagerSupplierAccountStatus.Retired, intl),
      filters: {
        's[current_account_status_name_eq]': 3,
      },
    },
    {
      count: suppliersStats?.banned,
      id: SupplierTabs.Banned,
      title: getStatusName(ManagerSupplierAccountStatus.Banned, intl),
      filters: {
        's[current_account_status_name_eq]': 4,
      },
    },
    {
      count: suppliersStats?.deleted,
      id: SupplierTabs.Deleted,
      title: getStatusName(ManagerSupplierAccountStatus.Deleted, intl),
      filters: {
        's[current_account_status_name_eq]': 5,
      },
    },
    {
      count: suppliersStats?.deceased,
      id: SupplierTabs.Deceased,
      title: getStatusName(ManagerSupplierAccountStatus.Deceased, intl),
      filters: {
        's[current_account_status_name_eq]': 6,
      },
    },
    {
      count: suppliersStats?.all,
      id: SupplierTabs.All,
      title: intl.formatMessage({ id: translationKeys.suppliers_page_all_tab }),
      filters: {},
    },
  ];

  const getTabFilters = () => {
    const activeTabItem = tabItems.find((item) => item.id === activeTab);

    if (activeTabItem) {
      return activeTabItem.filters;
    }
  };

  const handleFilterChange = (field: SuppliersFilterField, value: string) => {
    setFilters(getSuppliersFilters(filters, field, value));
  };

  const fetchSuppliers = async (page: number, clearCache = false) => {
    const { data, error } = await api.getSuppliers(
      {
        page,
        items: 20,
        's[s]': 'id desc',
        ...getTabFilters(),
        ...stripObject(filters),
      },
      setSuppliersLoading,
    );
    setSuppliersError(error);
    if (data?.suppliers) {
      let allSuppliers = suppliers;
      if (!clearCache) {
        for (const newSupplier of data.suppliers) {
          const newSupplierIndex = allSuppliers.findIndex(
            (supplier) => supplier.id === newSupplier.id,
          );

          if (newSupplierIndex === -1) {
            allSuppliers.push(newSupplier);
          } else {
            allSuppliers[newSupplierIndex] = newSupplier;
          }
        }
      } else {
        allSuppliers = data.suppliers;
      }

      setSuppliers(allSuppliers);
      setPage(data.page);
      setPages(data.pages);
      setSuppliersStats(data.statistics);
    }
  };

  const handleLoadMore = () => {
    fetchSuppliers(page + 1);
  };

  const handleReloadData = (hardReload = false) => {
    fetchSuppliers(page, hardReload);
  };

  const handleTabSelect = (newActiveTab: string) => {
    setActiveTab(newActiveTab);

    if (newActiveTab !== activeTab) {
      setSuppliers([]);
      setPage(1);
    }
  };

  useEffect(() => {
    if (Object.keys(filters).length === 0) {
      return;
    }

    setSuppliers([]);
    fetchSuppliers(1, true);
  }, [filters]);

  useEffect(() => {
    if (activeTab) {
      fetchSuppliers(page);
    }
  }, [activeTab]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 data-testid="suppliers-title">
            <FormattedMessage id={translationKeys.suppliers_page_title} />
          </h1>
          <div>
            <Button
              data-testid="new-supplier-button"
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => setCreateModalOpen(true)}
            >
              <FormattedMessage id={translationKeys.suppliers_page_new_supplier_button} />
            </Button>
          </div>
        </div>

        <div className={styles.tabsContainer}>
          <Tabs items={tabItems} onSelect={handleTabSelect} data-testid="supplier-tabs" />
        </div>

        <div className={styles.tableContainer}>
          <SuppliersTable
            suppliers={suppliers}
            error={suppliersError}
            isLoading={suppliersLoading}
            onFilterChange={handleFilterChange}
            onCompleteAction={handleReloadData}
          />
        </div>

        {!suppliersLoading && suppliers && page < pages && (
          <div className={styles.loadMoreButton}>
            <Button
              variant="secondary"
              size="large"
              onClick={handleLoadMore}
              data-testid="load-more-button"
            >
              <FormattedMessage id={translationKeys.jobs_page_load_more_button} />
            </Button>
          </div>
        )}
      </div>

      <CreateSupplierDrawer
        show={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onComplete={() => handleReloadData(true)}
      />
    </>
  );
};

export default Suppliers;
